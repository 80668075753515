import {
    Button, Col, Input, Layout, message, Row, Card, Select, Empty, Tooltip, Checkbox
} from 'antd';
import React from 'react';
import NavBar from '../components/header';
import {AutoImageGenerationAPI} from "../apis/vcs";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import NoAuth from "../components/noAuth";
import { UpCircleOutlined } from '@ant-design/icons';

const { Search } = Input;
const { Header, Content, Footer } = Layout;
const { TextArea } = Input;
const { Option } = Select;
const { Meta } = Card;

export default class AutoImageGeneration extends React.Component{

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            // signin stuff
            signin_name: null,
            signin_email: null,
            id_token_claims: null,
            // id_token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiI3YjAzZDAzNi03ZjQ4LTQ5YjAtYWE2MS0yNzhkZDFmMjc5OGIiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3L3YyLjAiLCJpYXQiOjE2NjMxNTUzMjQsIm5iZiI6MTY2MzE1NTMyNCwiZXhwIjoxNjYzMTU5MjI0LCJhaW8iOiJBVlFBcS84VEFBQUFDWTRwS3ZLQ2ltK2lpc1IxaXRaWHMzbmdJeFduOHZycGVTbVNzekNqNnBrZlVVUG00bldjamIydi9KOVdsZGE0MXhpSUUxRUdwRnlNRWlKdkJ5ODNYeXR6MDJ3d0JCSm5ZSkZtNzJzU01TST0iLCJuYW1lIjoiU2hpdmplZXQgQmhvc2FsZSIsIm5vbmNlIjoiZTg0ODA5NDktYzU3Ny00YzlkLWE1NmItYzkwNmI2ZjNlOGYxIiwib2lkIjoiMjk0YjhhMWMtNDU3MC00M2UwLTlkNmMtZGQ5ZjY0ZDcyYWJjIiwicHJlZmVycmVkX3VzZXJuYW1lIjoic2hpdmplZXQuYmhvc2FsZUBpbm1vYmkuY29tIiwicmgiOiIwLkFRNEE5SncxaVdDZW1VQ0F4SGRhRFA0bnB6YlFBM3RJZjdCSnFtRW5qZEh5ZVlzT0FKMC4iLCJzdWIiOiJsTGR0SFJoY1JOQzU3bmlQZ0E4dkk5VmNybjN1SHQxNGI0YXp2cVBRQWl3IiwidGlkIjoiODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3IiwidXRpIjoibWJCendsUUZja2V3RUFFQTBCd1JBQSIsInZlciI6IjIuMCJ9.IvxN3OgJzjzzn4vtJQHAyN5-C_Pt-YupI0pzpk8AM2hWT2j6JQZ5B5nuBSWrzZni5CZS4CFevxwjeKK-9EhnFLPC9mmAcpv3K6ULdIe9E7WPZGhmW68y1JaKbHUM6MgR9XVafGolH-AmEsWaahthrsvZTJ_kCktonZbzXSvsVxZ5F7Fco3RlK14X6dkw1XPJNjILfsKXVq8pEqDmZ272wumSKATVLJ4s-UDAxofgCBwSu9CgV1Z7KZum7xWTHyjW8Q0hHj93MFo4PEc-5wHZtS9db4bGU7yBfGT2SrkoraV0FT45nYBLOkSzyEtGXeOMETxeThF5U7KhPCNpwz468w",
            id_token: null,

            prompt: "",
            modes: ["LS", "Square","TV"],
            models: [
                "imagen",
            ],
            mode: "Square",
            format: "PNG",
            model: "imagen",
            title: "",
            description: "",
            categories: ["tech_and_business",
                "travel_and_lifestyle",
                "sports",
                "entertainment",
                "international_news",
                "food_and_health",
                "others",
            ],
            num_images: 5,
            category: "others",
            auto_image_generation_api_running: false,
            auto_image_generation_api_response: null,
            image_super_res_api_running: false,
            super_res: false,
            bookmark_auto_image_generation_api_running: false,
        };
        this.autoImageGeneration = this.autoImageGeneration.bind(this);
        this.bookmarkImageGeneration = this.bookmarkImageGeneration.bind(this);
      }
    

    autoImageGeneration = async () => {
        this.setState({
            auto_image_generation_api_running: true,
            auto_image_generation_api_response: null,
        })

        this.props.refreshIdToken().then((token) => {
            const payload = {
                title: this.state.title,
                description: this.state.description,
                category: this.state.category,
                mode: this.state.mode,
                num_images: this.state.num_images,
                image_model: this.state.model,
            }
            console.log("payload: ", payload)
            var status_code = 200;
            try {
                AutoImageGenerationAPI(payload, token).then((response) => {
                    status_code = response.status_code
                    if(response.status==false){
                        message.destroy()
                        message.error(response.message["detail"]+". " + response.status_text+". Login again or new tab :)")
                    }else{
                        message.destroy()
                        message.success("Image Generation Completed!")
                    }
                    this.setState({
                        auto_image_generation_api_running: false,
                        auto_image_generation_api_response: response,
                    })
                })
            } catch (error) {
                console.log(error)
                message.destroy()
                if (status_code == 500){
                    message.error("Failed to generate images! Most likely due to high load. Try again :)")
                }
                this.setState({
                    auto_image_generation_api_running: false,
                })
            }
        })
    }

    bookmarkImageGeneration = async () => {
        this.setState({
            bookmark_auto_image_generation_api_running: true,
        })

        this.props.refreshIdToken().then((token) => {
            const payload = {
                title: this.state.title,
                description: this.state.description,
                category: this.state.category,
                mode: this.state.mode,
                num_images: this.state.num_images,
                image_model: this.state.model,
            }
            var status_code = 200;
            try {
                AutoImageGenerationAPI(payload, token).then((response) => {
                    status_code = response.status_code
                    if(response.status==false){
                        message.destroy()
                        message.error(response.message["detail"]+". " + response.status_text+". Login again or new tab :)")
                    }else{
                        message.destroy()
                        message.success("Image Generation Completed!")
                    }
                    this.setState({
                        auto_image_generation_api_running: false,
                        auto_image_generation_api_response: response,
                    })
                    console.log("state setting complete")    
                })
            } catch (error) {
                console.log(error)
                message.destroy()
                if (status_code == 500){
                    message.error("Failed to generate images! Most likely due to high load. Try again :)")
                }
                this.setState({
                    auto_image_generation_api_running: false,
                })
            }
        })

    }

    
    componentDidMount(){
        // DO NOTHING HERE
    }

    render() {
        
        const createForm = (
            <div>
                <Layout>
            <Content>
            <br/>
            <br/>
            <h3> Ask AI to Generate Images </h3>
            <Row>
            <Col span={12}>
            <TextArea rows={2} style={{ width: '100%' }} placeholder="Enter the prompt" onChange={(e)=>{
                this.setState({
                    title: e.target.value
                })
            }} />
            </Col>

            <Col span={2}>
            <Tooltip trigger={['focus']} title={"LS/TV/Square based on usecase"} placement="topLeft">
                <Select
                    placeholder="Mode"
                    style={{
                        width: '100%',
                        paddingLeft: '5px',
                        paddingRight: '5px'
                    }}
                    value={this.state.mode}
                    onChange={(e)=>{
                        this.setState({
                            mode: e
                        })
                    }}
                    >
                    {this.state.modes.map((e) => {
                        return <Option
                        value={e}>{e}</Option>
                    })}
                    </Select>
                    </Tooltip>
                </Col>


                <Col span={2}>
                <Tooltip trigger={['focus']} title={"Category of the article. Use others if not sure"} placement="topLeft">
                <Select
                    placeholder="category"
                    style={{
                        width: '100%',
                        paddingLeft: '5px',
                        paddingRight: '5px'
                    }}
                    value={this.state.category}
                    onChange={(e)=>{
                        this.setState({
                            category: e
                        })
                    }}
                    >
                    {this.state.categories.map((e) => {
                        return <Option
                        value={e}>{e}</Option>
                    })}
                    </Select>
                    </Tooltip>
                </Col>


                <Col span={3}>
                <Tooltip trigger={['focus']} title={"Imagen use for default."} placement="topLeft">
                <Select
                    placeholder="Model"
                    style={{
                        width: '100%',
                        paddingLeft: '5px',
                        paddingRight: '5px'
                    }}
                    value={this.state.model}
                    defaultValue={this.state.model}
                    onChange={(e)=>{
                        this.setState({
                            model: e
                        })
                    }}
                    >
                    {this.state.models.map((e) => {
                        return <Option
                        value={e}>{e}</Option>
                    })}
                    </Select>
                    </Tooltip>
                </Col>


            <Col span={3}>
            <Button rows ={2} type="primary" style={{ width: '100%', marginLeft:'5px' }} block 
                onClick={this.autoImageGeneration} loading={this.state.auto_image_generation_api_running}
            >
             Get Images
            </Button>
            </Col>
            </Row>
            <br>
            </br>
            </Content>
            <br></br>
            <br></br>
            <Content>
                <Row style={{overflow: "scroll"}}>
                {
                    this.state.auto_image_generation_api_response == null
                    ? (
                        <Empty
                        style={{
                            paddingTop: "5px",
                            width: "100%",
                        }}
                        />
                    )
                    : 
                    (
                        this.state.auto_image_generation_api_response.message.images.map((image) => {
                            return (
                                image["urls"].map((image_url) => {
                                    return (
                                        <Col>
                                            <Card
                                                hoverable
                                                style={{ width: 240 }}
                                                cover={
                                                    <a href={image_url} target="_blank" rel="noreferrer">
                                                        <img alt="" src={image_url} style={{ width: '100%' }} />
                                                    </a>
                                                }
                                            >
                                                <Meta title={image["model_id"]} description={image["prompt"]} />
                                                <br></br>
                                            </Card>                     
                                        </Col>
                                    )
                                })
                            )
                        })
                    )
                }
                </Row>
            </Content>
          </Layout>
            </div>
        )

        return (
            <>
                <AuthenticatedTemplate>

            <Layout style={{'min-height':'100vh'}}>
            <NavBar
                authHandler={async (instance) => {await this.authHandler(instance)}}
                authRefresh={this.authRefresh}
                signin_name={this.props.username}
            />
            <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
              <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                {createForm}
              </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
            <img
                src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                alt="logo"
                style={{
                    height:'20px',
                    display:'inline',
                    paddingRight:'10px'
                }}/>
                 ©2021 Created by TAG AI Team</Footer>
          </Layout>
          </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <NoAuth />
            </UnauthenticatedTemplate>
            </>
        )
    }
}
