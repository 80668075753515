import {
    Button, Col, Layout, message, Row, Typography, Select, DatePicker, Space, Popconfirm
} from 'antd';
import React from 'react';
import moment from "moment";
import NavBar from '../components/header';
import { NicQuizQuestionSearchAPI, NicQuizGenerateFromQuestionsAPI, NicQuizQuestionGenerateAPI, NicQuizQuestionRejectAPI, NicQuizImageCropAPI, NicQuizViewAPI, NicQuizCreateFromQuestionsAPI, NicQuizUpdateAPI, NicQuizPublishAPI, NicQuizSearchAPI, NicQuizDeleteAPI, NicQuizGetImageSourceAPI } from "../apis/nicQuiz";
import { CreateCardAPI, TextCompletionAPI, IMSV2ImageSelectionAPI, ImageBlurAPI} from "../apis/vcs";
import { ImageCroppingModal } from "../components/imageCroppingModal"
import { LanguageDropDown } from "../components/languageDropDown"
import { NicQuizNewModal } from '../components/nicQuizNewModal';
import { NicQuizEditorModal } from '../components/nicQuizEditorModal';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { IMSImageSearchModal } from "../components/imsImageSearchModal";
import { QuestionSelectionModal } from "../components/questionSelectionModal"
import { GpsCardModal } from "../components/gpsCardModal";
import NoAuth from "../components/noAuth";

const { Content, Footer } = Layout;
const { Text } = Typography;


export default class NicQuizPortalV2 extends React.Component{

    constructor(props) {
        super(props);
        // Don't call this.setState() here!

        this.state = {
            // signin stuff
            signin_name: null,
            signin_email: null,
            id_token_claims: null,
            id_token: null,
            
            lang_options: [
                "es",
                "pt",
                "bn",
                "kn",
                "ta",
                "te",
                "hi",
                "en",
                "ba",
                "mr",
                "ms",
                "vi",
                "th",
                "fil,"
            ],

            category_options: [
                "all-categories",
                'daily_digest',
                'cricket',
                'sports',
                'entertainment',
                'technology',
                'travel_and_lifestyle',
                'automotive',
                'food_and_health',
                'national_v_two',
                'international',
                'business',
                'fashion',
                'music',
                'fun_facts',
                'games',
                'nature',
                'talent',
                'comedy',
                'tamil_news',
                'telugu_news',
                'kannada_news',
                'marathi_news',
                'bengali_news',
                'home_n_living',
                'celebrity',
                'education',
                'live_game_streams',
                'religion',
                'content_for_apps',
                'ipl',
            ],

            publisher_options: [
                "Free Press Journal",
                "FeedPost",
                "AFK Gaming",
                "CricTracker",
                "Sports Tiger",
                "The Newz Thing",
                "SportsTak",
                "Cric Tamil",
                "CricTracker Hindi",
                "CricTracker Bengali",
                "CricTracker বাংলা",
                "Maha Sports",
                "Vikatan",
                "Vistara News",
                "ET Now",
                "Times Now",
                "Zoom",
                "Gadgets 360",
                "PTI",
                "CNBC TV18",
                "NewsBytes"
            ],

            image_cdn_prefix: "https://g.glance-cdn.com/public/dynamic/1440x2560/",
            upload_endpoint: process.env.REACT_APP_UPLOAD_ENDPOINT,

            crop: { x: 0, y: 28.24 },
            zoom: 1,
            aspect: 7 / 4,
            cropping_image_url: null,
            cropping_question_index: null,
            cropped_area_pixels: null,
            image_cropping_modal_open: false,

            image_search_modal_open: false,
            ims_selected_image_id: null,
            ims_selected_image_source: null,
            ims_banner_image_url: null,
            image_ids: [],
            image_id_to_source_map: {},
            nic_editor_modal_open: false,
            gps_card_modal_open: false,
            nic_published_url: null,
            nic_quiz_search_api_running: false,
            nic_quiz_quizzes_search_response: null,
            nic_quiz_questions_search_response: null,
            nic_quiz_titles_search_response: null,
            search_categories: [],
            search_language: "en",
            search_modified_date: new Date(),
            search_skip: 0,
            search_limit: 100,

            selected_nic_quiz_index: null,

            nic_quiz_publish_api_running: false,
            nic_quiz_publish_url: process.env.REACT_APP_TRENDS_BASE_URL + "/newz/nic",

            nic_quiz_delete_api_running: false,
            nic_quiz_delete_response: null,

            target_language: "en",
            nic_quiz_api_running: false,
            nic_quiz_api_response: null,
            nic_quiz_update_api_running: false,
            nic_quiz_update_response: null,
            stage: null,

            nic_quiz: null,
            nic_questions: null,
            nic_quiz_title: null,
            nic_quiz_html: "<p>NIC quiz preview will be shown here</p>",
            nic_quiz_html_url: null,

            // gps modal assets
            ls_image_ids: [
                "20f6e3db-518b-4715-9f82-9ea4381a7820",
                "7f55e9cc-0b67-46ab-bd86-e6a8eaf65eb3",
                "3c55475f-e7cd-4424-8129-8ce03abeb76d",
                "7c92cde5-92b4-4c20-afd0-7b39d5d0fc08",
                "0bc8b7a4-7bb0-4099-a644-12511b1422da",
                "91804f6b-1948-45a9-9d00-96694dbbc463",
                "65fdd43f-9679-4bbc-9c63-af736eec1069",
                "51c0b144-eae6-4566-97a6-7398dae17117",
                "aa2cba95-ec8c-4e65-b080-8ffa779ab432",
                "9cdd745a-8dec-4dc4-b240-ac5c3bfd9bc6",
                "8e231b88305896c42861441392930fa79e6c11aa",
                "db938def-ffcb-4108-a5dd-bb7456c7dac0",
                "bba71fa0-8941-4d1c-9ca6-38850223a8ee",
                "43af23ab-f04c-41c9-849b-7c35181232a5",
                "7227fc65-a040-4a48-807f-f1f6fbe8916b",
                "ec30ff19-7142-4cf1-8378-dad60adadade",
                "fffa193e-dcf9-43db-b9ac-f0db76aa38ab",
            ],
            ls_image_cdn_prefix: process.env.REACT_APP_AWS_PREFIX,

            // new NIC quiz modal properties
            question_selection_modal_open: false,
            question_selection_fetch_count: 150,
            nic_quiz_manual_question_search_api_running: false,
            nic_quiz_automatic_question_search_api_running: false,
            nic_quiz_question_search_api_running: false,
            num_questions: 0,
            max_num_questions: 20,
            random_order: true,
            selected_categories: [],
            selected_publishers: [],
            fetched_questions: [],
            selected_questions: [],
            choosing_questions: [],
            rejected_questions: [],

            question_search_lookback_days: 2,

            nic_quiz_generate_api_running: false,
            nic_quiz_generate_api_response: null,
            nic_quiz_generate_question_api_running: false,
            nic_quiz_question_reject_api_running: false,
            nic_quiz_question_reject_api_response: null,
        };
        
        this.newNicQuiz = this.newNicQuiz.bind(this);
        this.updateNicQuiz = this.updateNicQuiz.bind(this);
        this.publishNicQuiz = this.publishNicQuiz.bind(this);
        this.deleteNicQuiz = this.deleteNicQuiz.bind(this);
        this.generateNicQuizFromQuestions = this.generateNicQuizFromQuestions.bind(this);
        this.searchNicQuizzes = this.searchNicQuizzes.bind(this);
        this.onCropChange = this.onCropChange.bind(this);
        this.onZoomChange = this.onZoomChange.bind(this);
        this.onCropComplete = this.onCropComplete.bind(this);
        this.saveCroppedImage = this.saveCroppedImage.bind(this);
        this.onAddBlankQuestion = this.onAddBlankQuestion.bind(this);
        this.onGenerateQuestion = this.onGenerateQuestion.bind(this);
        this.onMoveQuestion = this.onMoveQuestion.bind(this);
        this.toggleQuestionSelectionModal = this.toggleQuestionSelectionModal.bind(this);
        this.toggleImageCroppingModal = this.toggleImageCroppingModal.bind(this);
        this.toggleImageSearchModal = this.toggleImageSearchModal.bind(this);
        this.toggleNicQuizNewModal = this.toggleNicQuizNewModal.bind(this);
        this.toggleNicQuizEditorModal = this.toggleNicQuizEditorModal.bind(this);
        this.toggleGpsCardModal = this.toggleGpsCardModal.bind(this);
    }

    newNicQuiz = () => {
        this.setState({
            question_selection_modal_open: false,
            question_selection_fetch_count: 150,
            nic_quiz_manual_question_search_api_running: false,
            nic_quiz_automatic_question_search_api_running: false,
            nic_quiz_question_search_api_running: false,
            num_questions: 0,
            max_num_questions: 20,
            random_order: true,
            //selected_categories: [],  // TODO: get MultiSelectDropDown to reset its internal selection correctly
            //selected_publishers: [],
            fetched_questions: [],
            selected_questions: [],
            choosing_questions: [],
        }, () => {
            this.toggleNicQuizNewModal()
        })
    }

    getNicQuizTitle(questions) {
        var question_titles = ""
        for (var j = 0; j < questions.length; j++) {
            if (j > 0) {
                question_titles += " | "
            }
            question_titles += questions[j].title
        }
        return question_titles
    }

    searchNicQuizzes = async () => {
        if (this.state.search_modified_date == null) {
            message.destroy()
            message.info("Select a modified date!")
            return
        }

        message.loading("Searching NIC quizzes...", 0)
        this.setState({
            nic_quiz_search_api_running: true,
            nic_quiz_search_response: null,
            nic_quiz_html: "<p>NIC quiz preview will be shown here</p>"
        })

        this.props.refreshIdToken().then((token) => {
            const payload = {
                language: this.state.search_language,
                modified_date: this.state.search_modified_date,
                skip: this.state.search_skip,
                limit: this.state.search_limit
            }
            NicQuizSearchAPI(payload, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("NIC quiz search API failed!")
                    this.setState({
                        nic_quiz_search_api_running: false
                    })
                } else {
                    message.destroy()
                    message.success("NIC quiz search API success!")
                    var nic_quiz_titles = []
                    for (var i = 0; i < response.message.quizzes.length; i++) {
                        var title = this.getNicQuizTitle(response.message.questions[i])
                        nic_quiz_titles.push(title)
                    }
                    this.setState({
                        nic_quiz_search_api_running: false,
                        nic_quiz_quizzes_search_response: response.message.quizzes,
                        nic_quiz_questions_search_response: response.message.questions,
                        nic_quiz_titles_search_response: nic_quiz_titles,
                        nic_quiz: null,
                        nic_questions: null,
                        nic_quiz_title: null,
                        selected_nic_quiz_index: null
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to search NIC quizzes!")
                this.setState({
                    nic_quiz_search_api_running: false,
                })
            })

        }).catch((error) => {
            message.destroy()
            message.error("Failed to search NIC quizzes!", error)
            this.setState({
                nic_quiz_search_api_running: false,
            })
        })
    }

    onCropChange = (crop) => {
        this.setState({ crop });
    }

    onZoomChange = (zoom) => {
        this.setState({ zoom });
    }

    onCropComplete = (cropped_area, cropped_area_pixels) => {
        console.log('crop complete')
        this.setState({
            cropped_area_pixels: cropped_area_pixels,
        })
    }

    saveCroppedImage = () => {
        this.getCroppedImageUrl(
            this.state.cropping_image_url,
            this.state.cropped_area_pixels,
            this.state.cropping_question_index
        )
        this.toggleImageCroppingModal()
    }

    generateNicQuizFromQuestions = async () => {
        this.setState({
            nic_quiz_html: "<p>Quiz preview will be shown here</p>"
        })
        await this.generateNicQuizFromQuestionsRun()
    }

    generateNicQuizFromQuestionsRun = async () => {
        message.loading("Generating Quiz from questions...", 0)
        this.setState({
            nic_quiz_generate_api_running: true,
            nic_quiz_generate_api_response: null,
            nic_quiz_html: "<p>Quiz preview will be shown here</p>"
        })

        this.props.refreshIdToken().then((token) => {
            const payload = {
                questions: this.state.selected_questions,
                target_language: this.state.target_language,
                num_questions: this.state.num_questions,
                random_order: this.state.random_order,
                stage: "generated"
            }
            console.log("payload: ", payload)
            NicQuizGenerateFromQuestionsAPI(payload, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("NicQuizGenerateFromQuestions API failed!")
                } else {
                    message.destroy()
                    message.success("NicQuizGenerateFromQuestions API success!")
                    var nic_quiz = response.message.quiz
                    var nic_questions = response.message.questions

                    // get the NicQuiz view
                    const payload_view = {
                        quiz: nic_quiz,
                        questions: nic_questions
                    }
                    NicQuizViewAPI(payload_view, token).then((response2) => {
                        if (response2.status==false) {
                            message.destroy()
                            message.error("NicQuizView API failed!")
                        } else {
                            message.destroy()
                            message.success("NicQuizView API success!")
                            this.setState({
                                nic_quiz_html: response2.message.quiz_html,
                                nic_quiz_html_url: response2.message.quiz_html_url,
                            })
                        }

                        // persist NicQuiz to DB
                        nic_quiz.html_url = response2.message.quiz_html_url
                        const payload_create = {
                            quiz: nic_quiz,
                            questions: nic_questions
                        }
                        NicQuizCreateFromQuestionsAPI(payload_create, token).then((response3) => {
                            if (response3.status==false) {
                                message.destroy()
                                message.error("NicQuizUpdateAPI API failed!")
                                this.setState({
                                    nic_quiz_generate_api_running: false
                                })
                            } else {
                                message.destroy()
                                message.success("NicQuizUpdateAPI API success!")

                                // add new quiz to the start of the search results and select it
                                var quizzes_search_response = this.state.nic_quiz_quizzes_search_response
                                quizzes_search_response == null ? quizzes_search_response = [] : quizzes_search_response
                                quizzes_search_response.unshift(response3.message.quiz)

                                var questions_search_response = this.state.nic_quiz_questions_search_response
                                questions_search_response == null ? questions_search_response = [] : questions_search_response
                                questions_search_response.unshift(response3.message.questions)

                                var titles_search_response = this.state.nic_quiz_titles_search_response
                                titles_search_response == null ? titles_search_response = [] : titles_search_response
                                var title = this.getNicQuizTitle(response3.message.questions)
                                titles_search_response.unshift(title)

                                // update quiz and questions state
                                this.setState({
                                    nic_quiz_generate_api_running: false,
                                    nic_quiz_generate_api_response: response,
                                    nic_quiz_quizzes_search_response: quizzes_search_response,
                                    nic_quiz_questions_search_response: questions_search_response,
                                    nic_quiz_titles_search_response: titles_search_response,
                                    nic_quiz: quizzes_search_response[0],
                                    nic_questions: questions_search_response[0],
                                    nic_quiz_title: title,
                                    selected_nic_quiz_index: 0,
                                }, () => {
                                    console.log("nic_quiz: ", this.state.nic_quiz)
                                    console.log("nic_questions: ", this.state.nic_questions)
                                })
                            }
                        }).catch((error) => {
                            message.destroy()
                            message.error("Failed to save NicQuiz!")
                            this.setState({
                                nic_quiz_generate_api_running: false
                            })
                        })
                    }).catch((error) => {
                        console.log(error)
                        message.destroy()
                        message.error("Failed to generate Quiz view!")
                        this.setState({
                            nic_quiz_generate_api_running: false,
                        })
                    })
                }
            }).catch((error) => {
                console.log(error)
                message.destroy()
                message.error("Failed to generate Quiz!")
                this.setState({
                    nic_quiz_generate_api_running: false,
                })
            })
        })
    }

    markQuestionAsRejected = async (question) => {
        message.loading("Rejecting question...", 0)
        this.setState({
            nic_quiz_question_reject_api_running: true,
            nic_quiz_question_reject_api_response: null,
        })

        this.props.refreshIdToken().then((token) => {
            NicQuizQuestionRejectAPI(question.id, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("NicQuizQuestionReject API failed!")
                    this.setState({
                        nic_quiz_question_reject_api_running: false,
                        nic_quiz_question_reject_api_response: response.message
                    })
                } else {
                    message.destroy()
                    message.success("NicQuizQuestionReject API success!")
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to reject question!")
                this.setState({
                    nic_quiz_question_reject_api_running: false
                })
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to reject question!", error)
            this.setState({
                nic_quiz_question_reject_api_running: false
            })
        })
    }

    updateNicQuiz = async () => {
        message.loading("Updating Quiz view...", 0)
        this.setState({
            nic_quiz_update_api_running: true,
            nic_quiz_generate_api_response: null,
            nic_quiz_html: "<p>Quiz preview will be shown here</p>"
        })

        this.props.refreshIdToken().then((token) => {
            const payload_view = {
                quiz: this.state.nic_quiz,
                questions: this.state.nic_questions
            }
            NicQuizViewAPI(payload_view, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("NicQuiz view API failed!")
                } else {
                    message.destroy()
                    message.success("NicQuiz view API updated!")
                    var quiz = this.state.nic_quiz
                    quiz.html_url = response.message.quiz_html_url
                    this.setState({
                        nic_quiz_html: response.message.quiz_html,
                        nic_quiz_html_url: response.message.quiz_html_url,
                        nic_quiz: quiz
                    }, () => {
                        // update NicQuiz in DB
                        const payload_update = {
                            quiz: this.state.nic_quiz,
                            questions: this.state.nic_questions
                        }
                        NicQuizUpdateAPI(payload_update, token).then((response2) => {
                            if (response2.status==false) {
                                message.destroy()
                                message.error("NicQuiz database update API failed!")
                                this.setState({
                                    nic_quiz_update_api_running: false
                                })
                            } else {
                                message.destroy()
                                message.success("NicQuiz updated in database!")
                                this.setState({
                                    nic_quiz_update_api_running: false,
                                })
                            }
                        }).catch((error) => {
                            message.destroy()
                            message.error("Failed to update NicQuiz in database!")
                            this.setState({
                                nic_quiz_update_api_running: false
                            })
                        })
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to update Quiz view!")
                this.setState({
                    nic_quiz_update_api_running: false
                })
            })
        })
    }


    publishNicQuiz = async () => {
        message.loading("Publishing Quiz...", 0)
        this.setState({
            nic_quiz_publish_api_running: true,
        })

        this.props.refreshIdToken().then((token) => {
            NicQuizPublishAPI(this.state.nic_quiz.id, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("NicQuizPublish API failed!")
                } else {
                    message.destroy()
                    message.success("NicQuizPublish API success!")
                    var nic_quiz_quizzes_search_response = this.state.nic_quiz_quizzes_search_response
                    nic_quiz_quizzes_search_response[this.state.selected_nic_quiz_index] = response.message.quiz
                    this.setState({
                        nic_quiz: response.message.quiz,
                        nic_questions: response.message.questions,
                        nic_quiz_quizzes_search_response: nic_quiz_quizzes_search_response,
                        nic_quiz_publish_api_running: false,
                        nic_published_url: this.state.nic_quiz_publish_url + "/" + response.message.quiz.id
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to publish Quiz!")
                this.setState({
                    nic_quiz_publish_api_running: false
                })
            })
        })
    } 


    deleteNicQuiz = async () => {
        message.loading("Deleting NIC quiz...", 0)
        this.setState({
            nic_quiz_delete_api_running: true,
            nic_quiz_delete_response: null,
        })

        this.props.refreshIdToken().then((token) => {
            // delete NIC quiz and its questions from the DB
            NicQuizDeleteAPI(this.state.nic_quiz.id, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("NIC quiz delete API failed!")
                    this.setState({
                        nic_quiz_delete_api_running: false,
                        nic_quiz_delete_response: response.message
                    })
                } else {
                    message.destroy()
                    message.success("NIC quiz deleted!")

                    // remove the deleted quiz and its questions from the search results
                    var quizzes = this.state.nic_quiz_quizzes_search_response;
                    var questions = this.state.nic_quiz_questions_search_response;
                    var question_titles = this.state.nic_quiz_titles_search_response;
                    var index = quizzes.indexOf(this.state.nic_quiz);
                    if (index > -1) {
                        quizzes.splice(index, 1)
                        questions.splice(index, 1)
                        question_titles.splice(index, 1)
                    }

                    this.setState({
                        nic_quiz_delete_api_running: false,
                        nic_quiz_delete_response: response.message,
                        nic_quiz: null,
                        nic_questions: null,
                        nic_quiz_quizzes_search_response: quizzes,
                        nic_quiz_questions_search_response: questions,
                        nic_quiz_titles_search_response: question_titles,
                        selected_nic_quiz_index: null
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to delete NIC quiz!")
                this.setState({
                    nic_quiz_delete_api_running: false
                })
            })

        }).catch((error) => {
            message.destroy()
            message.error("Failed to delete NIC quiz!", error)
            this.setState({
                nic_quiz_delete_api_running: false
            })
        })
    }

    blurImage = async (image_url) => {
        try {
            const token = await this.props.refreshIdToken();
            const payload = { "url": image_url };
            const response = await ImageBlurAPI(payload, token);
            if (response.status === false) {
                message.destroy();
                message.error("Blur API failed!");
                throw new Error("Blur API failed");
            }
            message.destroy();
            message.success("Blurred Images!");

            if (response.status === true) {
                console.log('blurred: ', response.message.image_url);
                return response.message.image_url;
            }
        } catch (error) {
            message.destroy();
            message.error("Failed to blur image!");
            throw error;  // re-throw the error to be caught in the calling function
        }
        return image_url;
    }

    rephrase = async (title, characters) => {
        const payload = {
            prompt: "Make the following text as a clickbaity headline in only "+characters+" characters, this is for a quiz article, so make it question like: "+title,
            parameters: {
                max_tokens: characters,
            }
        }
        try {
            const token = await this.props.refreshIdToken();
            console.log("payload: ", payload)
            const response = await TextCompletionAPI(payload, token);
            if (response.status === false) {
                message.destroy();
                throw new Error("Text API failed");
            }
            message.destroy();

            if (response.status === true) {
                console.log('got the text: ', response.message.text);
                message.success("Rephrased Text!");
                // if the text has a trailing quote, remove it and then return
                if (response.message.text.endsWith('"')) {
                    return response.message.text.slice(0, -1);
                }
                return response.message.text;
            }
        } catch (error) {
            message.destroy();
            message.error("Failed to rephrase text!");
            throw error;  // re-throw the error to be caught in the calling function
        }
        return title;
    }

    pushCardToGPS = async (card) => {
        try {
            const token = await this.props.refreshIdToken();
            const response = await CreateCardAPI(card, token);
            if (response.status === false) {
                message.destroy();
                message.error("Could not create card!");
                throw new Error("Create Card API failed");
            }
            message.destroy();
            if (response.status === true) {
                message.success("Card created!");
                console.log("Card created: ", response.message);
                return response.message;
            }
        } catch (error) {
            message.destroy();
            message.error("Failed to create card!");
            throw error;  // re-throw the error to be caught in the calling function
        }
    }

    questionSelection = async (method) => {
        if (this.state.selected_categories.length == 0) {
            message.destroy()
            message.info("Please select some categories!")
            return
        }
        if (method == "manual") {
            this.setState({
                nic_quiz_manual_question_search_api_running: true,
                selected_questions: [],
                choosing_questions: [],
                rejected_questions: [],
            })
        } else if (method == "automatic") {
            this.setState({
                nic_quiz_automatic_question_search_api_running: true,
                selected_questions: []
            })
        }
        this.props.refreshIdToken().then((token) => {
            var day_range = this.state.question_search_lookback_days;
            var modified_date = moment().add(-(day_range-1), "days");
            const payload = {
                quiz_id: null,
                categories: this.state.selected_categories,
                publishers: this.state.selected_publishers,
                language: this.state.target_language,
                modified_date: modified_date,
                day_range: day_range,
                skip: 0,
                limit: 200,
            }
            NicQuizQuestionSearchAPI(payload, token).then((response) => {
                if (response.status == false) {
                    message.destroy()
                    message.error("NicQuizQuestionSearch API failed!")
                } else {
                    message.destroy()
                    message.success("Fetched some fresh questions!")
                }
                if (response.status == true) {
                    this.setState({
                        nic_quiz_question_search_api_running: false,
                        fetched_questions: response.message.questions
                    }, () => {
                        console.log("fetched_questions: ", this.state.fetched_questions)
                        if (method == "manual") {
                            // Open the modal for manual question selection after fetching candidate questions
                            this.toggleQuestionSelectionModal()
                        } else if (method == "automatic") {
                            // Select a random subset of the candidate questions
                            var fetched_questions = response.message.questions
                            var selected_questions = []
                            for (var i = 0; i < this.state.max_num_questions; i++) {
                                var random_index = Math.floor(Math.random() * fetched_questions.length)
                                selected_questions.push(fetched_questions[random_index])
                                fetched_questions.splice(random_index, 1)
                                if (fetched_questions.length == 0) {
                                    break
                                }
                            }
                            this.setState({
                                selected_questions: selected_questions,
                                num_questions: selected_questions.length,
                                nic_quiz_automatic_question_search_api_running: false
                            }, () => {
                                console.log("selected_questions: ", this.state.selected_questions)
                            })
                        }
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to fetch candidate questions!")
                this.setState({
                    nic_quiz_manual_question_search_api_running: false,
                    nic_quiz_automatic_question_search_api_running: false
                })
            })
        })
    }

    imageSelection = async (search_string) => {
        // TODO: Add image-selection API call
        // this.setState({
        //     clip_generate_api_running: true
        // })
        if (search_string == "") {
            message.destroy()
            message.info("Enter some text to search an image!")
            return
        }
        this.props.refreshIdToken().then((token) => {
            const payload = {
                params: {
                    "title": search_string,
                    "description": "",
                    "max_count": 30,
                    "duplicate_removal": true,
                    "blacklisted_removal": true,
                }
            }
            IMSV2ImageSelectionAPI(payload, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("Image Selection API failed!")
                } else {
                    message.destroy()
                    message.success("Fetched Some Fresh Images!")
                }
                if (response.status == true) {
                    console.log("Image Selection API Response: ", response.message)
                    const image_ids = response.message.images.map(item => item.id);
                    const image_id_to_source_map = response.message.images.reduce((acc, item) => {
                        acc[item.id] = item.source_name;
                        return acc;
                    }, {});
                    this.setState({
                        image_ids: image_ids,
                        selected_image_id: image_ids[0],
                        image_id_to_source_map: image_id_to_source_map,
                    })
                    console.log("Image Ids: ", this.state.image_ids) 
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to fetch images!")
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to fetch images!")
        })
    }

    getCroppedImageUrl = async (image_url, cropped_area_pixels, question_idx) => {
        this.props.refreshIdToken().then((token) => {
            const payload = {
                image_url: image_url,
                cropped_area_pixels: cropped_area_pixels
            }
            NicQuizImageCropAPI(payload, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("Quiz Image Cropping API failed!")
                } else {
                    message.destroy()
                    message.success("Cropped Image!")
                }
                if (response.status == true) {
                    console.log("Quiz Image Cropping API Response: ", response.message)
                    var nic_questions = this.state.nic_quiz_editor_editing_questions
                    if (this.state.nic_quiz_editor_image_type == "question") {
                        nic_questions[question_idx].question_image_url = response.message.image_cropped_url
                    } else if (this.state.nic_quiz_editor_image_type == "answer") {
                        nic_questions[question_idx].answer_image_url = response.message.image_cropped_url
                    }
                    this.setState({
                        nic_quiz_editor_editing_questions: nic_questions
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to crop image!"+error)
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to crop image!"+error)
        })
    }

    storeIMSSettings = async () => {
        console.log("ims_selected_image_id: ", this.state.ims_selected_image_id)
        var image_cropped_area_pixels
        var nic_questions = this.state.nic_quiz_editor_editing_questions
        if (this.state.nic_quiz_editor_image_type == "question") {
            nic_questions[this.state.ims_editing_question_index].question_image_id_selected = this.state.ims_selected_image_id
            image_cropped_area_pixels = this.state.nic_quiz_editor_editing_questions[this.state.ims_editing_question_index].question_image_cropped_area_pixels
        } else if (this.state.nic_quiz_editor_image_type == "answer") {
            nic_questions[this.state.ims_editing_question_index].answer_image_id_selected = this.state.ims_selected_image_id
            image_cropped_area_pixels = this.state.nic_quiz_editor_editing_questions[this.state.ims_editing_question_index].answer_image_cropped_area_pixels
        }
        this.setState({
            nic_quiz_editor_editing_questions: nic_questions
        }, () => {
            this.getImageSource()
        })
        this.getCroppedImageUrl(
            this.state.ims_banner_image_url,
            image_cropped_area_pixels,
            this.state.ims_editing_question_index
        )
        this.toggleImageSearchModal()
    }

    getImageSource = async () => {
        var nic_questions = this.state.nic_quiz_editor_editing_questions
        if (this.state.ims_selected_image_source != "") {
            if (this.state.nic_quiz_editor_image_type == "question") {
                nic_questions[this.state.ims_editing_question_index].question_image_attribution = this.state.ims_selected_image_source
            } else if (this.state.nic_quiz_editor_image_type == "answer") {
                nic_questions[this.state.ims_editing_question_index].answer_image_attribution = this.state.ims_selected_image_source
            }
            this.setState({
                nic_quiz_editor_editing_questions: nic_questions
            })
        } else {
            // get the source of the image selected in IMS if lookup map is not available
            await this.props.refreshIdToken().then((token) => {
                NicQuizGetImageSourceAPI(this.state.ims_selected_image_id, token).then((response) => {
                    if (response.status==false) {
                        message.destroy()
                        message.error("NicQuizGetImageSource API failed!")
                        return
                    } else {
                        if (this.state.nic_quiz_editor_image_type == "question") {
                            nic_questions[this.state.ims_editing_question_index].question_image_attribution = response.message.source_name
                        } else if (this.state.nic_quiz_editor_image_type == "answer") {
                            nic_questions[this.state.ims_editing_question_index].answer_image_attribution = response.message.source_name
                        }
                        this.setState({
                            nic_quiz_editor_editing_questions: nic_questions
                        })
                        return
                    }
                }).catch((error) => {
                    message.destroy()
                    message.error("Failed to get image source!")
                })
            }).catch((error) => {
                message.destroy()
                message.error("Failed to get image source!")
            })
        }
    }

    onAddBlankQuestion = async () => {
        var nic_quiz = this.state.nic_quiz_editor_editing_quiz
        var nic_questions = this.state.nic_quiz_editor_editing_questions
        var question = {
            quiz_id: null,
            language: nic_quiz.language,
            article: {
                url: "",
                title: "",
                source_name: "Glance",
                category: null,
                glance_id: null,
            },
            title: "",
            fact: "",
            question_text: "",
            answers: [
                {
                    is_correct: true,
                    answer_text: "",
                },
                {
                    is_correct: false,
                    answer_text: "",
                },
                {
                    is_correct: false,
                    answer_text: "",
                },
                {
                    is_correct: false,
                    answer_text: "",
                },
            ],
            correct_idx: 0,
            question_image_ids: [],
            question_image_id_selected: null,
            question_image_cropped_area_pixels: null,
            question_image_url: "",
            question_image_attribution: "",
            answer_image_ids: [],
            answer_image_id_selected: null,
            answer_image_cropped_area_pixels: null,
            answer_image_url: "",
            answer_image_attribution: "",
            show_answer_image: false,
            spec: {},
            stage: "added-by-user",

        }
        nic_questions.push(question)
        this.setState({
            nic_quiz_editor_editing_quiz: nic_quiz,
            nic_quiz_editor_editing_questions: nic_questions
        })
    }

    onGenerateQuestion = async (index) => {
        message.loading("Generating question...", 0)
        this.setState({
            nic_quiz_generate_question_api_running: true
        })

        this.props.refreshIdToken().then((token) => {
            const payload = {
                articles: [
                    {
                        "url": this.state.nic_quiz_editor_editing_questions[index].article.url,
                    }
                ],
                target_language: this.state.nic_quiz_editor_editing_quiz.language,
                num_questions: 1
            }
            NicQuizQuestionGenerateAPI(payload, token).then((response) => {
                // if response.status false or if the status code is 200 and the response.message.question is empty list
                if (response.status==false || (response.status==true && response.message.questions.length==0)) {
                    message.destroy()
                    message.error("NicQuizGenerateFromArticle API failed!")
                    this.setState({
                        nic_quiz_generate_question_api_running: false
                    })
                } else {
                    message.destroy()
                    message.success("NicQuizGenerateFromArticle API success!")
                    var nic_questions = this.state.nic_quiz_editor_editing_questions
                    console.log("nic_questions: ", nic_questions)
                    nic_questions[index] = response.message.questions[0]
                    this.setState({
                        nic_quiz_editor_editing_questions: nic_questions,
                        nic_quiz_generate_question_api_running: false
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to generate question!")
                this.setState({
                    nic_quiz_generate_question_api_running: false
                })
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to generate question!")
            this.setState({
                nic_quiz_generate_question_api_running: false
            })
        })
    }

    onMoveQuestion = async (direction, index) => {
        let questions = [...this.state.nic_quiz_editor_editing_questions];

        if (direction === "up" && index > 0) {
            [questions[index - 1], questions[index]] = [questions[index], questions[index - 1]];
        } else if (direction === "down" && index < questions.length - 1) {
            [questions[index], questions[index + 1]] = [questions[index + 1], questions[index]];
        }

        this.setState({ nic_quiz_editor_editing_questions: questions });
    }

    componentDidMount(){
        // DONT NEED ANYTHING HERE
    }

    toggleQuestionSelectionModal = () => {
        this.setState({
            nic_quiz_manual_question_search_api_running: !this.state.question_selection_modal_open,
            question_selection_modal_open: !this.state.question_selection_modal_open
        })
    }

    toggleImageSearchModal = () => {
        this.setState({
            image_search_modal_open: !this.state.image_search_modal_open
        })
    }

    toggleGpsCardModal = () => {
        console.log("Hit toggleGpsCardModal")
        console.log("nic_quiz_editor_editing_questions: ", this.state.nic_quiz_editor_editing_questions)
        console.log("nic_questions: ", this.state.nic_questions)
        this.setState({
            gps_card_modal_open: !this.state.gps_card_modal_open
        })
    }

    toggleImageCroppingModal = () => {
        this.setState({
            image_cropping_modal_open: !this.state.image_cropping_modal_open
        })
    }

    toggleNicQuizNewModal = () => {
        this.setState({
            nic_quiz_new_modal_open: !this.state.nic_quiz_new_modal_open
        })
    }

    toggleNicQuizEditorModal = () => {
        this.setState({
            nic_editor_modal_open: !this.state.nic_editor_modal_open
        })
    }

    render() {
        console.log("nic_quiz: ", this.state.nic_quiz)
        const createForm = (
            <div>
                <QuestionSelectionModal
                    isOpen={this.state.question_selection_modal_open}
                    onOk={() => {
                        var selected_questions = this.state.choosing_questions.filter(q => this.state.rejected_questions.indexOf(q) == -1)
                        this.setState({
                            selected_questions: selected_questions,
                            num_questions: selected_questions.length
                        }, () => {
                            this.toggleQuestionSelectionModal()
                        })
                    }}
                    onRejectQuestion={(question) => {
                        var rejected_questions = this.state.rejected_questions
                        rejected_questions.push(question)
                        this.setState({
                            rejected_questions: rejected_questions
                        })
                        this.markQuestionAsRejected(question)
                    }}
                    onViewUrl={(url) => {
                        window.open(url, "_blank")
                    }}
                    fetchedQuestions={this.state.fetched_questions}
                    questionsOnSelect={(e) => {
                        this.setState({
                            choosing_questions: e,
                            num_questions: e.length
                        })
                    }}
                    selectedQuestions={this.state.choosing_questions}
                    rejectedQuestions={this.state.rejected_questions}
                    api_running={this.state.nic_quiz_question_search_api_running}
                    maxSelectionCount={this.state.max_num_questions}
                    onClose={this.toggleQuestionSelectionModal}
                />

                <IMSImageSearchModal
                    isOpen={this.state.image_search_modal_open}
                    onOk={this.storeIMSSettings}
                    imageSelection={this.imageSelection}
                    default_value={this.state.search_text}
                    image_list={this.state.image_ids}
                    image_cdn_prefix={this.state.image_cdn_prefix}
                    imageOnSelect={(e)=>{
                        this.setState({
                            ims_selected_image_id: e.target.value,
                            ims_selected_image_source: this.state.image_id_to_source_map[e.target.value]?? "",
                            ims_banner_image_url: this.state.image_cdn_prefix + e.target.value + ".jpg",
                        })
                    }}
                    selectedImage={this.state.ims_banner_image_url}
                    signin_name={this.props.username}
                    upload_loading={this.state.image_upload_loading}
                    upload_endpoint={this.state.upload_endpoint}
                    id_token={this.props.idToken}
                    onImUploadChange={(e)=>{
                        if(e.file.status == 'done'){
                            this.setState({
                            ims_banner_image_url : e.file.response.image_url,
                            ims_selected_image_id: e.file.response.image_url,
                            ims_selected_image_source : 'upload'
                            });
                            message.success('Image Uploaded!')
                        }
                    }}
                />

                <GpsCardModal
                    isOpen={this.state.gps_card_modal_open}
                    onOk={(e) => {
                        this.toggleGpsCardModal()
                    }}
                    quiz={this.state.nic_quiz}
                    questions={this.state.nic_quiz_editor_editing_questions}
                    url={this.state.nic_published_url}
                    image_id_list={this.state.ls_image_ids}
                    image_cdn_prefix={this.state.ls_image_cdn_prefix}
                    rephrase={async (title, characters) => {
                        try {
                            const rephrasedText = await this.rephrase(title, characters);
                            console.log("summarized headline = ", rephrasedText);
                            return rephrasedText;
                        } catch (e) {
                            console.log("Error rephrasing text: ", e);
                            throw e;
                        }
                    }}
                    title={this.state.nic_questions?.[0]?.question_text}
                    pushCardToGPS={async (card) => {
                        console.log("pushing card to gps: ", card);
                        try {
                            const card_response = await this.pushCardToGPS(card);
                            console.log("card = ", card_response);
                            return card_response;
                        } catch (e) {
                            console.log("Error pushing card: ", e);
                            throw e;
                        }

                    }}
                />

                <ImageCroppingModal
                    isOpen={this.state.image_cropping_modal_open}
                    image_url={this.state.cropping_image_url}
                    onClose={this.saveCroppedImage}
                    crop={this.state.crop}
                    zoom={this.state.zoom}
                    aspect={this.state.aspect}
                    onCropChange={this.onCropChange}
                    onZoomChange={this.onZoomChange}
                    onCropComplete={this.onCropComplete}
                />

                <NicQuizEditorModal
                    isOpen={this.state.nic_editor_modal_open}
                    centered
                    onOk={(e) => {
                        var nic_quiz = this.state.nic_quiz_editor_editing_quiz
                        var nic_questions = this.state.nic_quiz_editor_editing_questions
                        this.setState({
                            nic_quiz: nic_quiz,
                            nic_questions: nic_questions
                        })
                        this.updateNicQuiz()
                        this.toggleNicQuizEditorModal()
                    }}
                    onCancel={this.toggleNicQuizEditorModal}
                    onClose={this.toggleNicQuizEditorModal}
                    onDeleteQuestion={(e) => {
                        var nic_quiz = this.state.nic_quiz_editor_editing_quiz
                        var nic_questions = this.state.nic_quiz_editor_editing_questions
                        nic_quiz.question_ids.splice(e, 1)
                        nic_questions.splice(e, 1)
                        this.setState({
                            nic_quiz_editor_editing_quiz: nic_quiz,
                            nic_quiz_editor_editing_questions: nic_questions
                        })
                    }}
                    onChangeQuestion={(field_name, question_index, e) => {
                        var nic_questions = this.state.nic_quiz_editor_editing_questions
                        nic_questions[question_index][field_name] = e
                        this.setState({
                            nic_quiz_editor_editing_questions: nic_questions
                        })
                    }}
                    onChangeQuestionArticle={(field_name, question_index, e) => {
                        var nic_questions = this.state.nic_quiz_editor_editing_questions
                        nic_questions[question_index]['article'][field_name] = e
                        this.setState({
                            nic_quiz_editor_editing_questions: nic_questions
                        })
                    }}
                    onChangeAnswer={(field_name, question_index, answer_index, e) => {
                        var nic_questions = this.state.nic_quiz_editor_editing_questions
                        nic_questions[question_index].answers[answer_index][field_name] = e
                        this.setState({
                            nic_quiz_editor_editing_questions: nic_questions
                        })
                    }}
                    onReplaceImage={(question_index, image_type) => {
                        var nic_questions = this.state.nic_quiz_editor_editing_questions
                        console.log("nic_questions: ", nic_questions)
                        if (image_type == "question") {
                            console.log("replace image for question: ", question_index)
                            this.setState({
                                image_ids: nic_questions[question_index].question_image_ids,
                                ims_banner_image_url: nic_questions[question_index].question_image_url,
                                ims_editing_question_index: question_index,
                                nic_quiz_editor_image_type: "question"
                            })
                        } else if (image_type == "answer") {
                            console.log("replace image for answer: ", question_index)
                            var image_ids
                            if (nic_questions[question_index].answer_image_ids == null) {
                                image_ids = []
                            } else {
                                image_ids = nic_questions[question_index].answer_image_ids
                            }
                            this.setState({
                                image_ids: image_ids,
                                ims_banner_image_url: nic_questions[question_index].answer_image_url,
                                ims_editing_question_index: question_index,
                                nic_quiz_editor_image_type: "answer"
                            })
                        }
                    }}
                    onRejectQuestion={(e) => {
                        var nic_quiz = this.state.nic_quiz_editor_editing_quiz
                        var nic_questions = this.state.nic_quiz_editor_editing_questions
                        nic_quiz.question_ids.splice(e, 1)
                        nic_questions.splice(e, 1)
                        this.setState({
                            nic_quiz_editor_editing_quiz: nic_quiz,
                            nic_quiz_editor_editing_questions: nic_questions
                        })
                        this.markQuestionAsRejected(e)
                    }}
                    toggleImageSearchModal={this.toggleImageSearchModal}
                    onCropImage={(question_index, image_type) => {
                        var nic_questions = this.state.nic_quiz_editor_editing_questions
                        if (image_type == "question") {
                            this.setState({
                                cropping_image_url: this.state.image_cdn_prefix+nic_questions[question_index].question_image_id_selected+".jpg",
                                cropping_cropped_area_pixels: nic_questions[question_index].question_image_cropped_area_pixels,
                                cropping_question_index: question_index,
                                nic_quiz_editor_image_type: "question"
                            })
                        } else if (image_type == "answer") {
                            var cropping_image_url
                            if (nic_questions[question_index].answer_image_id_selected == null) {
                                cropping_image_url = nic_questions[question_index].answer_image_url
                            } else {
                                cropping_image_url = this.state.image_cdn_prefix+nic_questions[question_index].answer_image_id_selected+".jpg"
                            }
                            this.setState({
                                cropping_image_url: cropping_image_url,
                                cropping_cropped_area_pixels: nic_questions[question_index].answer_image_cropped_area_pixels,
                                cropping_question_index: question_index,
                                nic_quiz_editor_image_type: "answer"
                            })
                        }
                        this.toggleImageCroppingModal()
                    }}
                    onBlurImage={(question_index, unblur_answer) => {
                        const updatedQuestions = [...this.state.nic_quiz_editor_editing_questions];
                        if (unblur_answer) {
                            // show the original unblurred image with the answer
                            updatedQuestions[question_index].show_answer_image = true;
                            updatedQuestions[question_index].answer_image_url = updatedQuestions[question_index].question_image_url;
                            updatedQuestions[question_index].answer_image_attribution = updatedQuestions[question_index].question_image_attribution;
                            updatedQuestions[question_index].answer_image_ids = updatedQuestions[question_index].question_image_ids;
                            updatedQuestions[question_index].answer_image_id_selected = updatedQuestions[question_index].question_image_id_selected;
                        }
                        this.blurImage(updatedQuestions[question_index].question_image_url)
                        .then((blurredImageUrl) => {
                            updatedQuestions[question_index].question_image_url = blurredImageUrl;
                            this.setState({ nic_quiz_editor_editing_questions: updatedQuestions }, () => {
                                console.log("Updated State:", updatedQuestions[question_index].question_image_url);
                            });
                        })
                        .catch((e) => {
                            console.log("Error blurring image: ", e);
                        })
                    }}
                    toggleImageCroppingModal={this.toggleImageCroppingModal}
                    onAddBlankQuestion={this.onAddBlankQuestion}
                    onGenerateQuestion={this.onGenerateQuestion}
                    onMoveQuestion={this.onMoveQuestion}
                    category_options={this.state.category_options}
                    editing_quiz={this.state.nic_quiz_editor_editing_quiz}
                    editing_questions={this.state.nic_quiz_editor_editing_questions}
                    nic_quiz_generate_question_api_running={this.state.nic_quiz_generate_question_api_running}
                    image_cdn_prefix={this.state.image_cdn_prefix}
                />

                <NicQuizNewModal
                    isOpen={this.state.nic_quiz_new_modal_open || this.state.nic_quiz_generate_api_running}
                    centered
                    onOk={this.toggleNicQuizNewModal}
                    onCancel={this.toggleNicQuizNewModal}
                    onClose={this.toggleNicQuizNewModal}
                    onViewUrl={(url) => {
                        window.open(url, "_blank")
                    }}
                    getGlobalProperty={(property) => {
                        return this.state[property]
                    }}
                    setGlobalProperty={(property, value) => {
                        this.setState({
                            [property]: value
                        })
                    }}
                    onQuestionSelection={this.questionSelection}
                    onGenerateNicQuiz={this.generateNicQuizFromQuestions}
                />
                
                <Layout>
                    <Content>
                        <br/>
                        <br/>
                        <h3>Search for NIC Quizzes</h3>
                        <Row style={{ width: '100%' }}>
                            <Col span={2}>
                                <Text style={{ fontWeight: 'bold' }}>
                                    Language
                                </Text>
                                <br />
                                <LanguageDropDown
                                    language={this.state.target_language}
                                    language_options={this.state.lang_options}
                                    onLanguageSelect={(e) => {
                                        console.log("onLanguageSelect - " + e)
                                        this.setState({
                                            search_language: e
                                        })
                                    }}
                                    disabled={true}
                                />
                            </Col>
                            <Col span={4} style={{ paddingLeft: '20px'}}>
                                <Text style={{ fontWeight: 'bold' }}>
                                    Modified Date
                                </Text>
                                <br />
                                <Space direction="vertical">
                                    <DatePicker
                                        defaultValue={moment(this.state.search_modified_date)}
                                        onChange={(e) => {
                                            this.setState({
                                                search_modified_date: e
                                            })
                                        }}
                                    />
                                </Space>

                            </Col>
                            <Col span={3} style={{ paddingLeft: '20px'}}>
                                <Button type="primary" block
                                    style={{
                                        marginTop: '21px'
                                    }}
                                    onClick={this.searchNicQuizzes} loading={this.state.nic_quiz_search_api_running}
                                >
                                    Search 
                                </Button>
                            </Col>
                            <Col span={12}></Col>
                            <Col span={3}>
                                <Button type="primary" block
                                    style={{
                                        marginTop: '21px'
                                    }}
                                    onClick={this.newNicQuiz}
                                >
                                    New Quiz
                                </Button>
                            </Col>
                        </Row>
                        <br/>
                        <br/>
                        <Row>
                            <Col span={24}>
                                <Text style={{ fontWeight: 'bold' }}>
                                    Search Results
                                </Text>
                                <br />
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder={this.state.nic_quiz_quizzes_search_response == null ? "Search for NIC quizzes" : "Select a NIC quiz:"}
                                    optionFilterProp="children"
                                    onChange={(e) => {
                                        this.setState({
                                            nic_quiz: this.state.nic_quiz_quizzes_search_response[e],
                                            nic_questions: this.state.nic_quiz_questions_search_response[e],
                                            nic_quiz_title: this.state.nic_quiz_titles_search_response[e],
                                            selected_nic_quiz_index: e
                                        })
                                    }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    value={this.state.selected_nic_quiz_index}
                                >
                                    {this.state.nic_quiz_quizzes_search_response ? (
                                        this.state.nic_quiz_quizzes_search_response.map((quiz, index) => {
                                            return (
                                                <Select.Option value={index}>{"[" + quiz.stage + "] " + this.state.nic_quiz_titles_search_response[index]}</Select.Option>
                                            )
                                        })
                                    ) : (
                                        <>
                                        </>
                                    )}
                                </Select>
                            </Col>
                        </Row>
                        <br/>
                        <br/>
                        <Row style={{ visibility: this.state.nic_quiz == null ? 'hidden' : 'visible'}}>
                            <Col span={8}>
                                <Text style={{ fontWeight: 'bold' }}>
                                    NIC Quiz Preview
                                </Text>
                                <br />
                                <iframe

                                    style={{
                                        width: '100%',
                                        height: '640px'
                                    }}
                                    title="view"
                                    src={this.state.nic_quiz && 'html_url' in this.state.nic_quiz ? this.state.nic_quiz.html_url : ""}/>
                            </Col>
                            <Col span={16} style={{ paddingLeft: '40px'}}>
                                <Row>
                                    <Col span={24}>
                                        <Text style={{ fontWeight: 'bold' }}>
                                        NIC Quiz Details
                                        </Text>
                                        <br />
                                        <table style={{ lineHeight: '2.5em'}}>
                                            <tr>
                                                <td><b>Language:</b></td>
                                                <td>{this.state.nic_quiz && 'target_language' in this.state.nic_quiz ? this.state.nic_quiz.target_language : ""}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Questions:</b></td>
                                                <td>{this.state.nic_questions ? this.state.nic_questions.length : ""}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: '20px', verticalAlign: 'top' }}><b>Question&nbsp;Titles:</b></td>
                                                <td style={{ lineHeight: '1.6em', paddingTop: '7px' }}>{this.state.nic_quiz_title ? this.state.nic_quiz_title : ""}</td>
                                            </tr>
                                            <tr>
                                                <td><b>State:</b></td>
                                                <td>{this.state.nic_quiz && 'stage' in this.state.nic_quiz ? this.state.nic_quiz.stage : ""}</td>
                                            </tr>
                                        </table>
                                        <br />
                                        <br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Text style={{ fontWeight: 'bold' }}>
                                            NIC Quiz Actions
                                        </Text>
                                        <br />
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                var nic_quiz = this.state.nic_quiz
                                                var nic_questions = this.state.nic_questions
                                                this.setState({
                                                    nic_quiz_editor_editing_quiz: nic_quiz,
                                                    nic_quiz_editor_editing_questions: nic_questions
                                                }, () => {
                                                    this.toggleNicQuizEditorModal()
                                                })
                                            }}
                                            style={{
                                                width: 320,
                                                overflow: 'clip'
                                            }}
                                            block
                                            loading={this.state.nic_quiz_update_api_running}
                                            disabled={
                                                this.state.nic_quiz === null
                                                || (this.state.nic_quiz && this.state.nic_quiz.stage == "published")
                                                || this.state.nic_quiz_publish_api_running
                                            }
                                        >
                                            Edit and Update NIC Quiz
                                        </Button>
                                        <br />
                                        <br />
                                        <Popconfirm
                                            title="Do you really want to publish this quiz? Editing will not be possible after publishing."
                                            onConfirm={(e) => {
                                                this.publishNicQuiz()
                                            }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button
                                                type="primary"
                                                style={{
                                                    width: 320,
                                                    overflow: 'clip'
                                                }}
                                                loading={this.state.nic_quiz_publish_api_running}
                                                disabled={
                                                    this.state.nic_quiz === null
                                                    || (this.state.nic_quiz && this.state.nic_quiz.stage == "published")
                                                    || this.state.nic_quiz_update_api_running
                                                    || (this.state.nic_questions && this.state.nic_questions.length == 0)
                                                }
                                            >
                                                Publish Quiz
                                            </Button>
                                        </Popconfirm>
                                        <br />
                                        <br />
                                        <Popconfirm
                                            title="Delete this NIC quiz ?"
                                            onConfirm={(e) => {
                                                this.deleteNicQuiz()
                                            }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button
                                                type="primary"
                                                style={{
                                                    width: 320,
                                                    overflow: 'clip'
                                                }}
                                                block
                                                loading={this.state.nic_quiz_delete_api_running}
                                            >
                                                Delete NIC Quiz
                                            </Button>
                                        </Popconfirm>
                                    </Col>
                                    <Col span={12} style={{ paddingLeft: "20px"}}>
                                        <Button
                                            style={{
                                                marginTop: '20px',
                                                display: 'block'
                                            }}
                                            onClick={() => navigator.clipboard.writeText(this.state.nic_quiz.html_url)}
                                            disabled={
                                                this.state.nic_quiz === null
                                                || this.state.nic_quiz_update_api_running
                                            }
                                        >
                                            COPY QUIZ PREVIEW URL (internal use only)
                                        </Button>
                                        <br />
                                        <Button 
                                            style={{
                                                display: 'block'
                                            }}
                                            onClick={() => navigator.clipboard.writeText(this.state.nic_quiz_publish_url + "/" + this.state.nic_quiz.id)}
                                            disabled={
                                                this.state.nic_quiz === null
                                                || (this.state.nic_quiz && this.state.nic_quiz.stage != "published")
                                            }
                                        >
                                            COPY QUIZ PUBLISHED URL (for sharing)
                                        </Button>
                                        <br />
                                        <Button 
                                            style={{
                                                display: 'block'
                                            }}
                                            onClick={(e) => {
                                                console.log("bhai sahab")
                                                console.log(this.state.nic_quiz_publish_url + "/" + this.state.nic_quiz.id)
                                                const published_url = this.state.nic_quiz_publish_url + "/" + this.state.nic_quiz.id
                                                this.setState({
                                                    nic_published_url: published_url
                                                }, () => {
                                                    this.toggleGpsCardModal()
                                                })
                                            }}
                                            disabled={
                                                this.state.nic_quiz === null
                                                || (this.state.nic_quiz && this.state.nic_quiz.stage != "published")
                                            }
                                        >
                                            Send to GPS
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </div>
        )

        return (
            <>
                <AuthenticatedTemplate>
                    <Layout style={{'min-height':'100vh'}}>
                        <NavBar
                            authHandler={async (instance) => {await this.authHandler(instance)}}
                            authRefresh={this.authRefresh}
                            signin_name={this.state.signin_name}
                        />
                        <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
                            <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                                {createForm}
                            </div>
                        </Content>
                        <Footer style={{ textAlign: 'center' }}>
                        <img
                            src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                            alt="logo"
                            style={{
                                height:'20px',
                                display:'inline',
                                paddingRight:'10px'
                            }}/>
                            ©2023 Created by TAG AI Team
                        </Footer>
                    </Layout>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <NoAuth />
                </UnauthenticatedTemplate>
            </>
        )
    }
}
