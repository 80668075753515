const API_BASE_URL = process.env.REACT_APP_LEX_BACKEND;


export async function VideoGenerateAPI(payload, token=null){
  return await postRequestVideoAPI("generate", payload, token);
}

export async function VideoCreateAPI(payload, token=null){
  return await postRequestVideoAPI("create", payload, token);
}

export async function VideoUpdateAPI(payload, token=null){
  return await postRequestVideoAPI("update", payload, token);
}

export async function VideoUpdatedbAPI(payload, token=null){
  return await postRequestVideoAPI("updatedb", payload, token);
}

export async function VideoDeleteAPI(video_id, token=null) {
  return await getRequestVideoAPI("delete", video_id, token);
}

export async function VideoPublishAPI(video_id, token=null){
  return await getRequestVideoAPI("publish", video_id, token);
}

export async function VideoSearchAPI(payload, token=null){
  return await postRequestVideoAPI("search", payload, token);
}

export async function postRequestVideoAPI(api_function, payload, token = null) {
  let request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
    body: JSON.stringify(payload),
  };
  if (token) request.headers["Authorization"] = `Bearer ${token}`;
  const rawResponse = await fetch(
    API_BASE_URL + "/api/v1/video/" + api_function,
    request
  );
  if (rawResponse.ok) {
    const content = await rawResponse.json();

    return { status: true, message: content };
  }
  // TODO: Add the same error extract method to other api calls
  let content = null;
  try {
    content = await rawResponse.json();
  } catch (error) {
    content = await rawResponse.text();
  }
  return { status: false, message: content };
}

export async function getRequestVideoAPI(api_function, id, token = null) {
  let request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  };
  if (token) request.headers["Authorization"] = `Bearer ${token}`;
  const rawResponse = await fetch(
    API_BASE_URL + "/api/v1/video/" + api_function + "/" + id,
    request
  );
  if (rawResponse.ok) {
    const content = await rawResponse.json();

    return { status: true, message: content };
  }
  // TODO: Add the same error extract method to other api calls
  let content = null;
  try {
    content = await rawResponse.json();
  } catch (error) {
    content = await rawResponse.text();
  }
  return { status: false, message: content };
}

export async function ClipGenerateAPI(payload, token = null) {
  // console.log('About to send this payload:', payload)
  // return 0
  let request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
    body: JSON.stringify(payload),
  };
  if (token) request.headers["Authorization"] = `Bearer ${token}`;
  const rawResponse = await fetch(
    API_BASE_URL + "/api/v1/clip/generate",
    request
  );
  if (rawResponse.ok) {
    const content = await rawResponse.json();

    return { status: true, message: content };
  }
  // TODO: Add the same error extract method to other api calls
  let content = null;
  try {
    content = await rawResponse.json();
  } catch (error) {
    content = await rawResponse.text();
  }
  return { status: false, message: content };
}

export async function VideoStitchAPI(payload, token = null) {
  // console.log('About to send this payload:', payload)
  // return 0
  let request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
    body: JSON.stringify(payload),
  };
  if (token) request.headers["Authorization"] = `Bearer ${token}`;
  const rawResponse = await fetch(
    API_BASE_URL + "/api/v1/stitch/generate",
    request
  );
  if (rawResponse.ok) {
    const content = await rawResponse.json();

    return { status: true, message: content };
  }
  // TODO: Add the same error extract method to other api calls
  let content = null;
  try {
    content = await rawResponse.json();
  } catch (error) {
    content = await rawResponse.text();
  }
  return { status: false, message: content };
}

export async function ImageSelectionAPI(payload, token = null) {
  // console.log('About to send this payload:', payload)
  // return 0
  let request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
    body: JSON.stringify(payload),
  };
  if (token) request.headers["Authorization"] = `Bearer ${token}`;
  const rawResponse = await fetch(
    API_BASE_URL + "/api/v1/image/generate",
    request
  );
  if (rawResponse.ok) {
    const content = await rawResponse.json();

    return { status: true, message: content };
  }
  // TODO: Add the same error extract method to other api calls
  let content = null;
  try {
    content = await rawResponse.json();
  } catch (error) {
    content = await rawResponse.text();
  }
  return {"status":false, "message":content}
}

export async function IMSImageSelectionAPI(payload, token = null) {
  // console.log('About to send this payload:', payload)
  // return 0
  let request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
    body: JSON.stringify(payload),
  };
  if (token) request.headers["Authorization"] = `Bearer ${token}`;
  const rawResponse = await fetch(
    API_BASE_URL + "/api/v1/ims/search",
    request
  );
  if (rawResponse.ok) {
    const content = await rawResponse.json();

    return { status: true, message: content };
  }
  // TODO: Add the same error extract method to other api calls
  let content = null;
  try {
    content = await rawResponse.json();
  } catch (error) {
    content = await rawResponse.text();
  }

  return {"status":false, "message":content}
}

export async function IMSV2ImageSelectionAPI(payload, token = null) {
  // console.log('About to send this payload:', payload)
  // return 0
  payload.params = {
    ...payload.params,
    apply_deduplication: false,
    apply_dpp: false,
  };

  let request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
    body: JSON.stringify(payload),
  };
  if (token) request.headers["Authorization"] = `Bearer ${token}`;
  const rawResponse = await fetch(
    API_BASE_URL + "/api/v1/imsv2/search",
    request
  );
  if (rawResponse.ok) {
    const content = await rawResponse.json();

    return { status: true, message: content };
  }
  // TODO: Add the same error extract method to other api calls
  let content = null;
  try {
    content = await rawResponse.json();
  } catch (error) {
    content = await rawResponse.text();
  }

  return {"status":false, "message":content}
}


export async function ImageBlurAPI(payload, token = null) {
  // console.log('About to send this payload:', payload)
  // return 0
  let request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
    body: JSON.stringify(payload),
  };
  if (token) request.headers["Authorization"] = `Bearer ${token}`;
  const rawResponse = await fetch(
    API_BASE_URL + "/api/v1/image/blurimage",
    request
  );
  if (rawResponse.ok) {
    const content = await rawResponse.json();

    return { status: true, message: content };
  }
  // TODO: Add the same error extract method to other api calls
  let content = null;
  try {
    content = await rawResponse.json();
  } catch (error) {
    content = await rawResponse.text();
  }

  return {"status":false, "message":content}
}

export async function TextCompletionAPIwithTimeout(payload, token = null, timeoutMs = 3000) {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), timeoutMs);

  try {
    let request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
      body: JSON.stringify(payload),
      signal: controller.signal  // Add this line
    };

    if (token) {
      request.headers['Authorization'] = `Bearer ${token}`;
    }

    const rawResponse = await fetch(API_BASE_URL + "/api/v1/text/completion", request);

    clearTimeout(timeoutId);  // Clear the timeout if the request completes

    if (rawResponse.ok) {
      const content = await rawResponse.json();
      return {
        status: true,
        message: content
      };
    }

    // Error handling
    let content = null;
    try {
      content = await rawResponse.json();
    } catch (error) {
      content = await rawResponse.text();
    }
    return {
      status: false,
      message: content
    };

  } catch (error) {
    clearTimeout(timeoutId);  // Clear the timeout in case of error

    if (error.name === 'AbortError') {
      return {
        status: false,
        message: 'Request timed out'
      };
    }

    return {
      status: false,
      message: error.message
    };
  }
}

export async function TextCompletionAPI(payload, token=null){
  // console.log('About to send this payload:', payload)
  // return 0
  let request={
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',

    },
    body: JSON.stringify(payload)
  }
  if(token)
    request.headers['Authorization'] = `Bearer ${token}`
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/text/completion",
    request
  );
  if(rawResponse.ok){
      const content = await rawResponse.json();

      return {"status":true,
          "message":content
          }
  }
  // TODO: Add the same error extract method to other api calls
  let content=null
  try {
    content = await rawResponse.json()
  } catch (error) {
    content = await rawResponse.text()
  }
  return {"status":false, "message":content}
}

export async function CreateCardAPI(payload, token=null){
  // console.log('About to send this payload:', payload)
  // return 0
  let request={
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',

    },
    body: JSON.stringify(payload)
  }
  if(token)
    request.headers['Authorization'] = `Bearer ${token}`
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/helpers/card",
    request
  );
  if(rawResponse.ok){
      const content = await rawResponse.json();

      return {"status":true,
          "message":content
          }
  }
  // TODO: Add the same error extract method to other api calls
  let content=null
  try {
    content = await rawResponse.json()
  } catch (error) {
    content = await rawResponse.text()
  }
  return {"status":false, "message":content}
}


export async function ImageGenerationAPI(payload, token=null){
  // console.log('About to send this payload:', payload)
  // return 0
  let request={
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json'
    },
    body: JSON.stringify(payload)
  }
  if(token)
    request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/image/ga/generate", 
    request
  );
  if(rawResponse.ok){
      const content = await rawResponse.json();

      return {"status":true,
          "message":content,
          "status_code": rawResponse.status,
          "status_text": rawResponse.statusText
          }
  }
  // TODO: Add the same error extract method to other api calls
  let content=null
  try {
    content = await rawResponse.json()
  } catch (error) {
    content = await rawResponse.text()
  }
  return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
}

export async function AutoImageGenerationAPI(payload, token=null){
  // console.log('About to send this payload:', payload)
  // return 0
  let request={
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json'
    },
    body: JSON.stringify(payload)
  }
  if(token)
    request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/image/imagen", 
    request
  );
  if(rawResponse.ok){
      const content = await rawResponse.json();

      return {"status":true,
          "message":content,
          "status_code": rawResponse.status,
          "status_text": rawResponse.statusText
          }
  }
  // TODO: Add the same error extract method to other api calls
  let content=null
  try {
    content = await rawResponse.json()
  } catch (error) {
    content = await rawResponse.text()
  }
  return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
}


export async function LSTransitionAPI(payload,token=null){
  // console.log('About to send this payload:', payload)
  // return 0
  let request={
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json'
    },
    body: JSON.stringify(payload)
  }
  if(token)
    request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/image/ga/resize", 
    request
  );
  if(rawResponse.ok){
      const content = await rawResponse.json();

      return {"status":true,
          "message":content,
          "status_code": rawResponse.status,
          "status_text": rawResponse.statusText
          }
  }
  // TODO: Add the same error extract method to other api calls
  let content=null
  try {
    content = await rawResponse.json()
  } catch (error) {
    content = await rawResponse.text()
  }
  return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
}

export async function ImageSuperResolutionAPI(payload,token=null){
  // console.log('About to send this payload:', payload)
  // return 0
  let request={
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json'
    },
    body: JSON.stringify(payload)
  }
  if(token)
    request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL+"/api/v1/image/superresolution",
    request
  );
  if(rawResponse.ok){
      const content = await rawResponse.json();

      return {"status":true,
          "message":content,
          "status_code": rawResponse.status,
          "status_text": rawResponse.statusText
          }
  }
  // TODO: Add the same error extract method to other api calls
  let content=null
  try {
    content = await rawResponse.json()
  } catch (error) {
    content = await rawResponse.text()
  }
  return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
}


export async function ImageAutoCorrectionAPI(payload,token=null){
  // console.log('About to send this payload:', payload)
  // return 0
  let request={
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json'
    },
    body: JSON.stringify(payload)
  }
  if(token)
    request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL+"/api/v1/image/autocorrectimage",
    request
  );
  if(rawResponse.ok){
      const content = await rawResponse.json();

      return {"status":true,
          "message":content,
          "status_code": rawResponse.status,
          "status_text": rawResponse.statusText
          }
  }
  // TODO: Add the same error extract method to other api calls
  let content=null
  try {
    content = await rawResponse.json()
  } catch (error) {
    content = await rawResponse.text()
  }
  return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
}


export async function LipSyncAPI(payload,token=null){
  // console.log('About to send this payload:', payload)
  // return 0
  let request={
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json'
    },
    body: JSON.stringify(payload)
  }
  if(token)
    request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/video/lipsync", 
    request
  );
  if(rawResponse.ok){
      const content = await rawResponse.json();

      return {"status":true,
          "message":content,
          "status_code": rawResponse.status,
          "status_text": rawResponse.statusText
          }
  }
  // TODO: Add the same error extract method to other api calls
  let content=null
  try {
    content = await rawResponse.json()
  } catch (error) {
    content = await rawResponse.text()
  }
  return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
}

export async function IMSAddAPI(payload,token=null){
  // console.log('About to send this payload:', payload)
  // return 0
  let request={
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json'
    },
    body: JSON.stringify(payload)
  }
  if(token)
    request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/ims/add", 
    request
  );
  if(rawResponse.ok){
      const content = await rawResponse.json();

      return {"status":true,
          "message":content,
          "status_code": rawResponse.status,
          "status_text": rawResponse.statusText
          }
  }
  // TODO: Add the same error extract method to other api calls
  let content=null
  try {
    content = await rawResponse.json()
  } catch (error) {
    content = await rawResponse.text()
  }
  return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
}


export async function getHistoricalData(token=null, skip=0, limit=100){
  let request={
      method: 'GET',
      headers: {
          'accept': 'application/json'
      }
  }
  if(token)
      request.headers['Authorization'] = `Bearer ${token}`;

  const rawResponse = await fetch(API_BASE_URL + "/api/v1/video/lipsync/multi?skip="+skip+"&limit="+limit, 
  request
  );
  
  if(rawResponse.ok){
      const content = await rawResponse.json();

      return {
          "status":true,
          "message":content,
          "status_code": rawResponse.status,
          "status_text": rawResponse.statusText
          }
  }
  
  let content=null
  try {
      content = await rawResponse.json()
  } catch (error) {
      content = await rawResponse.text()
  }
  return {
      "status":false, 
      "message":content, 
      "status_code": rawResponse.status, 
      "status_text": rawResponse.statusText
  }
}



export async function TranscriptionAPI(payload,token=null){
  // console.log('About to send this payload:', payload)
  // return 0
  let request={
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json'
    },
    body: JSON.stringify(payload)
  }
  if(token)
    request.headers['Authorization'] = `Bearer ${token}`;
    const rawResponse = await fetch(API_BASE_URL + "/api/v1/transcription/create", 
    request
  );
  if(rawResponse.ok){
      const content = await rawResponse.json();

      return {"status":true,
          "message":content,
          "status_code": rawResponse.status,
          "status_text": rawResponse.statusText
          }
  }
  // TODO: Add the same error extract method to other api calls
  let content=null
  try {
    content = await rawResponse.json()
  } catch (error) {
    content = await rawResponse.text()
  }
  return {"status":false, "message":content, "status_code": rawResponse.status, "status_text": rawResponse.statusText}
}


export async function getTranscribeHistoricalData(token=null, skip=0, limit=100){
  let request={
      method: 'GET',
      headers: {
          'accept': 'application/json'
      }
  }
  if(token)
      request.headers['Authorization'] = `Bearer ${token}`;

  const rawResponse = await fetch(API_BASE_URL + "/api/v1/transcription/list?skip="+skip+"&limit="+limit, 
  request
  );
  
  if(rawResponse.ok){
      const content = await rawResponse.json();

      return {
          "status":true,
          "message":content,
          "status_code": rawResponse.status,
          "status_text": rawResponse.statusText
          }
  }
  
  let content=null
  try {
      content = await rawResponse.json()
  } catch (error) {
      content = await rawResponse.text()
  }
  return {
      "status":false, 
      "message":content, 
      "status_code": rawResponse.status, 
      "status_text": rawResponse.statusText
  }
}