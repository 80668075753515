import {
    Button, Col, Input, Layout, message, Row, Card, Select, Empty,Modal,Tooltip,Popconfirm,Image, Tag,Checkbox,Pagination
} from 'antd';
import React from 'react';
import {CheckCircleTwoTone} from '@ant-design/icons';
import NavBar from '../components/header';
import {IMSV2SearchAPI, IMSV2BatchUpdateAPI} from '../apis/ims';
import {UploadButton} from '../components/uploadButton';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import NoAuth from "../components/noAuth";

const { Search } = Input;
const { Header, Content, Footer } = Layout;
const { TextArea } = Input;
const { Option } = Select;
const { Meta } = Card;

export default class IMSSearch extends React.Component{

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            // signin stuff
            signin_name: null,
            signin_email: null,
            id_token_claims: null,
            // id_token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiI3YjAzZDAzNi03ZjQ4LTQ5YjAtYWE2MS0yNzhkZDFmMjc5OGIiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3L3YyLjAiLCJpYXQiOjE2NjMxNTUzMjQsIm5iZiI6MTY2MzE1NTMyNCwiZXhwIjoxNjYzMTU5MjI0LCJhaW8iOiJBVlFBcS84VEFBQUFDWTRwS3ZLQ2ltK2lpc1IxaXRaWHMzbmdJeFduOHZycGVTbVNzekNqNnBrZlVVUG00bldjamIydi9KOVdsZGE0MXhpSUUxRUdwRnlNRWlKdkJ5ODNYeXR6MDJ3d0JCSm5ZSkZtNzJzU01TST0iLCJuYW1lIjoiU2hpdmplZXQgQmhvc2FsZSIsIm5vbmNlIjoiZTg0ODA5NDktYzU3Ny00YzlkLWE1NmItYzkwNmI2ZjNlOGYxIiwib2lkIjoiMjk0YjhhMWMtNDU3MC00M2UwLTlkNmMtZGQ5ZjY0ZDcyYWJjIiwicHJlZmVycmVkX3VzZXJuYW1lIjoic2hpdmplZXQuYmhvc2FsZUBpbm1vYmkuY29tIiwicmgiOiIwLkFRNEE5SncxaVdDZW1VQ0F4SGRhRFA0bnB6YlFBM3RJZjdCSnFtRW5qZEh5ZVlzT0FKMC4iLCJzdWIiOiJsTGR0SFJoY1JOQzU3bmlQZ0E4dkk5VmNybjN1SHQxNGI0YXp2cVBRQWl3IiwidGlkIjoiODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3IiwidXRpIjoibWJCendsUUZja2V3RUFFQTBCd1JBQSIsInZlciI6IjIuMCJ9.IvxN3OgJzjzzn4vtJQHAyN5-C_Pt-YupI0pzpk8AM2hWT2j6JQZ5B5nuBSWrzZni5CZS4CFevxwjeKK-9EhnFLPC9mmAcpv3K6ULdIe9E7WPZGhmW68y1JaKbHUM6MgR9XVafGolH-AmEsWaahthrsvZTJ_kCktonZbzXSvsVxZ5F7Fco3RlK14X6dkw1XPJNjILfsKXVq8pEqDmZ272wumSKATVLJ4s-UDAxofgCBwSu9CgV1Z7KZum7xWTHyjW8Q0hHj93MFo4PEc-5wHZtS9db4bGU7yBfGT2SrkoraV0FT45nYBLOkSzyEtGXeOMETxeThF5U7KhPCNpwz468w",
            id_token: null,
            input_image_url: null,
            image_upload_loading: false,
            aws_cdn_prefix: process.env.REACT_APP_AWS_PREFIX,
            ims_search_api_running: false,
            ims_search_api_response: null,
            upload_endpoint: process.env.REACT_APP_UPLOAD_ENDPOINT,
            image_upload_loading: false,
            title: null,
            images: null,
            duplicate_removal_v2: true,
            use_elastic_search_index: false,
            partner_ids: [],
            partner_id_options: {
                "VIVO": "feb4e5ad0039fee86e4870dd3ca88c28a8ee1a1e",
                "VIVO_API": "d4b9c453aedd185aceb77c12ed0f18ac",
                "OPPO": "d1f143bd74d8977c781204281564a93f",
                "OPPO_API" : "b27cbb02e015198844783efe6db1d580",
                "TRANSSION": "b366fc7948b55d41976cf3014f616f6f",
            },
            type: null,
            type_options: ["LS", "raw", "TV"],
            statuses: ["approved", "NONE"],
            statuses_options: ["approved", "blacklisted", "NONE"],
            statuses_color_options: {
                "approved": "green",
                "blacklisted": "red",
                "NONE": "grey",
                "": "orange",
                " ": "yellow",
            },
            preferred_regions: [],
            preferred_regions_options: ["in", "id", "us", "br", "co", "mx", "vn", "th", "jp", "ph", "my"],
            updated_last_used_at_per_dimension: {},
            custom_dimensions: [
                "IN-hi",
                "IN-en",
                "TH",
                "VN",
                "MY",
                "JP",
            ],
            source_name_search_str: null,
            last_used_at_in_days: null,
            all: true,
            keywords: false,
            uploaded: false,
            last_used_at_in_days_options: [2, 10, 15, 30, 90, 120, 180],
            partner_id_options_keys: [],
            partner_id_options_values: [],
            max_count : 100,
            image_url : "",
             // pagination related
             currentPage: 1,
             pageSize: 24,
             offset: 0,
             edited_images_map: {},
        };
        for (var key in this.state.partner_id_options) {
            this.state.partner_id_options_keys.push(key);
            this.state.partner_id_options_values.push(this.state.partner_id_options[key]);
        }

        this.imageManagementSearch = this.imageManagementSearch.bind(this);
      }

      getDaysSinceLastUsed(dateString) {
        const lastUsedDate = new Date(dateString);
        const currentDate = new Date();
        const timeDifference = Math.abs(currentDate - lastUsedDate);
        const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
        return daysDifference;
      }

      filterImages() {
        if (!Array.isArray(this.state.images)) {
            console.warn("Props images is not an array", this.state.images);
            return;
        }
    
        let filteredImages = [...this.state.images]; // start with all images
    
        if (this.state.all) {
            console.log("Inside all")
            // if all is selected, use all images without filtering
            this.setState({ images: filteredImages });
            return;
        }
    
        if (this.state.keywords) {
            console.log("Inside keywords")
            filteredImages.sort((a, b) => {
                if (a.elastic_score && b.elastic_score) {
                    return b.elastic_score - a.elastic_score; 
                } else if (a.elastic_score) {
                    return -1;
                } else if (b.elastic_score) {
                    return 1;
                }
                return 0;
            });
        }
    
        if (this.state.uploaded) {
            console.log("Inside uploaded")
            filteredImages = filteredImages.filter(img => img.spec && img.spec.is_uploaded === true);
        }
    
        this.setState({ images: filteredImages });
        console.log("Filtered images",this.state.images)
    }

      imageManagementSearch = async () => {
        message.loading("Fetching Images..", 0)
        this.props.refreshIdToken().then((token) => {
            const payload = {
                params: {
                    "title": this.state.title,
                    "max_count" : this.state.max_count,
                    "apply_deduplication": false,
                    "apply_dpp": false,
                }
            }
            if (this.state.statuses) {
                payload["params"]["statuses"] = this.state.statuses
            }
            if (this.state.partner_ids) {
                payload["params"]["partner_ids"] = this.state.partner_ids
            }
            if (this.state.preferred_regions) {
                payload["params"]["preferred_regions"] = this.state.preferred_regions
            }
            if (this.state.source_name_search_str) {
                payload["params"]["source_name_search_str"] = this.state.source_name_search_str
            }
            if (this.state.last_used_at_in_days) {
                payload["params"]["last_used_at_in_days"] = this.state.last_used_at_in_days
            }
            if (this.state.type) {
                payload["params"]["type"] = this.state.type
            }
            if (this.state.image_url && this.state.image_url !== ""){
                payload["params"]["image_url"] = this.state.image_url
            }
            if (this.state.keywords) {
                payload["params"]["use_elastic_search_index"] = true
            }
            IMSV2SearchAPI(payload, token).then((response) => {
                if(response.status===false){
                    message.destroy()
                    message.error(response.message["detail"]+". " + response.status_text+". Login again or new tab :)")
                }else{
                    message.destroy()
                    message.success("Image Search Complete!")
                }
                this.setState({
                    ims_search_api_running: false,
                    ims_search_api_response: response,
                    images: response.message.images,
                }, () => {
                    // Callback function after state update
                    this.filterImages();
                });
               
            }).catch ((error) => {
                message.destroy()
                message.error("Failed to fetch images. Try again?")
                this.setState({
                    ims_search_api_running: false,
                })
            })
        })
    }

    markImageAsSafe = async (image_index) => {
        message.loading("Marking image as safe for vivo + oppo", 0)
        this.props.refreshIdToken().then((token) => {
            var images = this.state.images;
            var image = images[image_index];
            var allowlist_partner_ids = image.allowlist_partner_ids
            ? [...image.allowlist_partner_ids, ...this.state.partner_id_options_values]
            : [...this.state.partner_id_options_values];
            image["allowlist_partner_ids"] = allowlist_partner_ids;
            const payload = {
                packets: [
                    {
                        id: image.id,
                        allowlist_partner_ids: allowlist_partner_ids,
                    }
                ]
            }
            IMSV2BatchUpdateAPI(payload, token).then((response) => {
                if(response.status==false){
                    message.destroy()
                    message.error(response.message["detail"]+". " + response.status_text+". Login again or new tab :)")
                }else{
                    message.destroy()
                    message.success("Marked safe!")
                }
                this.setState({
                    images: images,
                })
            }).catch((error)=>{
                message.destroy()
                message.error("Failed to mark as safe. Try again?")
            })
        })
    }

    markImageAsRecentlyUsed = async (image_index) => {
        message.loading("Marking image as recently used", 0)
        this.props.refreshIdToken().then((token) => {
            var images = this.state.images;
            var image = images[image_index];
            const currentDate = new Date().toISOString();
            image["last_used_at"] = currentDate;
            const updated_regions = this.state.updated_last_used_at_per_dimension[image_index];
            // Update the original dictionary with the updated values
            // Ensure last_used_at_per_dimension and oppo_vivo exist
            image["last_used_at_per_dimension"] = image["last_used_at_per_dimension"] || {};
            image["last_used_at_per_dimension"]["oppo_vivo"] = image["last_used_at_per_dimension"]["oppo_vivo"] || {};
            if (updated_regions) {
            for (let i = 0; i < updated_regions.length; i++) {
                image["last_used_at_per_dimension"]["oppo_vivo"][updated_regions[i]] = currentDate;
            }
            }
            const payload = {
            packets: [
                {
                id: image.id,
                last_used_at: currentDate,
                ...(updated_regions && { last_used_at_per_dimension: image["last_used_at_per_dimension"] }),
                }
            ]
            };
            IMSV2BatchUpdateAPI(payload, token).then((response) => {
                if(response.status===false){
                    message.destroy()
                    message.error(response.message["detail"]+". " + response.status_text+". Login again or new tab :)")
                }else{
                    message.destroy()
                    message.success("Marked as used!")
                    this.setState({
                        images: images,
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to mark as used. Try again?")
            })
        })
    }

    componentDidMount(){
        // DO NOTHING HERE
    }

    render() {
        
        const createForm = (
            <div>
                <Layout>
            <Content>
            <Row>
                <h2>IMS Search Portal</h2>
            </Row>

            <Row>
            <Col span={9}>
            <h3> Search Query</h3>
            <Input placeholder='Search...' onChange={(e)=>{
                this.setState({
                    title: e.target.value
                })
            }}
            onPressEnter={
                !this.state.ims_search_api_running ? this.imageManagementSearch : undefined
              }
            />
            </Col>
            {/*

            <Col span={3}>
                <Checkbox
                style={{
                    marginTop: '30px',
                    marginLeft: '30px'
                }}
                checked={this.state.duplicate_removal_v2} onChange={(e)=>{
                    this.setState({
                        duplicate_removal_v2: e.target.checked
                    })
                }}>Stricter Duplicates</Checkbox>
            </Col>
            */}
            <Col span={2}>
            <h3> Partner id</h3>  
                <Tooltip trigger={['focus']} title={"Any specific Partner id?"} placement="topLeft">
                    <Select
                        mode="multiple"
                        placeholder="Specific Partner id?"
                        style={{
                                width: '100%',
                                paddingLeft: '5px',
                                paddingRight: '5px'
                                }}
                        value={this.state.partner_ids}
                        allowClear={true}
                        onChange={(e)=>{
                            this.setState({
                                    partner_ids: e
                                })
                            }}
                        >
                        {this.state.partner_id_options_keys.map(key => {
                            return <Option value={this.state.partner_id_options[key]}>{key}</Option>
                        })}
                    </Select>
                </Tooltip>
            </Col>
            <Col span={3}>
            <h3> Preferred Regions</h3>  
                <Tooltip trigger={['focus']} title={"Any specific region?"} placement="topLeft">
                    <Select
                        mode="multiple"
                        placeholder="Specific region?"
                        style={{
                                width: '100%',
                                paddingLeft: '5px',
                                paddingRight: '5px'
                                }}
                        value={this.state.preferred_regions}
                        allowClear={true}
                        onChange={(e)=>{
                            this.setState({
                                preferred_regions: e
                                })
                            }}
                        >
                        {this.state.preferred_regions_options.map(k => {
                            return <Option value={k}>{k}</Option>
                        })}
                    </Select>
                </Tooltip>
            </Col>
            <Col span={3}>
            <h3> Source</h3>  
                <Tooltip trigger={['focus']} title={"Any specific source"} placement="topLeft">
                <Input placeholder='Search...'
                    onChange={(e)=>{
                        this.setState({
                            source_name_search_str: e.target.value
                            })
                    }}
                />
                </Tooltip>
            </Col>
            <Col>
            <h3> Type</h3>  
                <Tooltip trigger={['focus']} title={"Any specific type"} placement="topLeft">
                    <Select
                        placeholder="Type"
                        style={{
                                width: '100%',
                                paddingLeft: '5px',
                                paddingRight: '5px'
                                }}
                        value={this.state.type}
                        allowClear={true}
                        onChange={(e)=>{
                                this.setState({
                                    type: e
                                    })
                                }}
                        >
                        {this.state.type_options.map((e) => {
                            return <Option
                                value={e}></Option>
                        })}
                    </Select>
                </Tooltip>
            </Col>

            <Col span={2}>
            <h3> Status</h3>  
                <Tooltip trigger={['focus']} title={"Any specific status"} placement="topLeft">
                    <Select
                        mode="multiple"
                        placeholder="Status"
                        style={{
                                width: '100%',
                                paddingLeft: '5px',
                                paddingRight: '5px'
                                }}
                        value={this.state.statuses}
                        allowClear={true}
                        onChange={(e)=>{
                                this.setState({
                                    statuses: e
                                    })
                                }}
                        >
                        {this.state.statuses_options.map((e) => {
                            return <Option
                                value={e}></Option>
                        })}
                    </Select>
                </Tooltip>
            </Col>
            <Col span={2}>
            <h3> Filter Last X days</h3>
                <Tooltip trigger={['focus']} title={"Filtering on last X days"} placement="topLeft">
                    <Select
                        placeholder="last X days"
                        style={{
                                width: '100%',
                                paddingLeft: '5px',
                                paddingRight: '5px'
                                }}
                        value={this.state.last_used_at_in_days}
                        allowClear={true}
                        onChange={(e)=>{
                            this.setState({
                                last_used_at_in_days: e
                                })
                            }}
                        >
                        {this.state.last_used_at_in_days_options.map((e) => {
                            return <Option
                                value={e}></Option>
                        })}
                    </Select>
                </Tooltip>
            </Col>
            <Col span ={2}>
                <h3> Filter on Search Result</h3>
                    <Row span={6}>
                        <Checkbox 
                        defaultChecked={true}
                        onChange={(e) => {
                            this.setState({
                                all: e.target.checked
                            })
                        }}>All</Checkbox>
                    </Row>
                    <Row span={6}>
                        <Checkbox onChange={(e) => {
                            this.setState({
                                keywords: e.target.checked
                            })
                        }}>Keywords</Checkbox>
                    </Row>
                    <Row span={6}>
                        <Checkbox onChange={(e) => {
                            this.setState({
                                uploaded: e.target.checked
                            })
                        }}>Uploaded</Checkbox>
                    </Row>
            </Col>
            <Col span={2}></Col>
            <Col span={4}>
            <Row>

            <h3> Reverse Search </h3>
                    <UploadButton
                        signin_name={this.props.username}
                        upload_loading={this.state.image_upload_loading}
                        upload_endpoint={this.state.upload_endpoint}
                        id_token={this.props.idToken}
                        onImUploadChange={(e) => {
                            if (e.file.status == 'done') {
                                message.success(' Uploaded!')
                                var temp_video_upload_url = e.file.response.image_url
                                this.setState({
                                    image_url: temp_video_upload_url
                                })
                            } else if (e.file.status == 'removed') {
                                this.setState({
                                    image_url: ''
                                })
                            }
                        }
                    }
                    />
            </Row>
            </Col>
            </Row>
            <br/>
            <Row justify="center" align="middle" style={{ height: '100%' }}>
            <Col span={5}>
            <Button rows ={2} type="primary" style={{ width: '100%', marginLeft:'5px' }} block 
                onClick={this.imageManagementSearch} loading={this.state.ims_search_api_running}
            >
             Search Images
            </Button>
            </Col>
            </Row>
            <br>
            </br>
            </Content>
            <br></br>
            <br></br>
            <Content>
                        
                <Row style={{overflow: "scroll",justifyContent: 'center',
                                                alignItems: 'center',}}>
                {
                    this.state.ims_search_api_response == null
                    ? (
                        <Empty
                        style={{
                            paddingTop: "5px",
                            width: "100%",
                        }}
                        />
                    )
                    :
                    (   
                        <>
                        <Row>
                        <Pagination
                            current={this.state.currentPage}
                            pageSize={this.state.pageSize}
                            total={this.state.images.length}
                            onChange={(page, pageSize) => {
                                    var offset = (page - 1) * this.state.pageSize;
                                    this.setState({
                                        currentPage: page,
                                        offset: offset,
                                    });
                                }
                            }
                        />
                        <br></br>
                        <br></br>
                        </Row>
                        <Row>
                        {this.state.images.slice((this.state.currentPage - 1) * this.state.pageSize, this.state.currentPage * this.state.pageSize).map((img, img_index) => {
                            return (
                                <Col span={4}>

                                    <Card
                                        hoverable
                                        style={{
                                        width: 240
                                        }}
                                        cover={
                                            <img 
                                                alt="" 
                                                src={
                                                    (this.state.type === "LS") 
                                                        ? (this.state.aws_cdn_prefix + img["id"] + ".jpg") 
                                                        : (this.state.aws_cdn_prefix + img["id"] + ".jpg")
                                                }
                                                onError={(e) => {
                                                    // Ensure img["spec"]["image_urls"] exists and is an array
                                                    const fallbackUrls = img["spec"] && Array.isArray(img["spec"]["image_urls"]) 
                                                        ? img["spec"]["image_urls"] 
                                                        : [];
                                        
                                                    // Use the first URL from the fallback list
                                                    if (fallbackUrls.length > 0) {
                                                        e.target.src = fallbackUrls[0];
                                                    }
                                                }} 
                                            />
                                        }
                                    >
                                        <Meta title={img["source_name"]} />
                                        <br/>
                                        {
                                            (img["status"])
                                            ? <Tag color={this.state.statuses_color_options[img["status"]]}>{img["status"]}</Tag>
                                            : <Tag color={"grey"}>NO STATUS</Tag>
                                        }
                                        <br></br>
                                        <p>Last used {this.getDaysSinceLastUsed(img["last_used_at"])}  days before</p>
                                        <p>
                                        {
                                            Object.entries(img.last_used_at_per_dimension?.oppo_vivo ?? {}).map(([region, lastUsedAt]) => {
                                            return <React.Fragment key={region}>{region}: {this.getDaysSinceLastUsed(lastUsedAt)} days, </React.Fragment>
                                            })
                                        }
                                        </p>
                                        <Select
                                            mode="multiple"
                                            placeholder="Mark Used For"
                                            style={{
                                                width: '100%',
                                                paddingLeft: '5px',
                                                paddingRight: '5px',
                                            }}
                                            allowClear={true}
                                            onChange={(e)=>{
                                                var updated_last_used_at_per_dimension = this.state.updated_last_used_at_per_dimension;
                                                updated_last_used_at_per_dimension[img_index] = e;
                                                this.setState({
                                                    updated_last_used_at_per_dimension: updated_last_used_at_per_dimension
                                                })
                                            }}
                                            >
                                            {this.state.custom_dimensions.map(dim => {
                                                return <Option value={dim}>{dim}</Option>
                                            })}
                                        </Select>

                                        <Popconfirm
                                            title="Do you really want to mark this image as used now for the geos selected?"
                                            onConfirm={() => {
                                                this.markImageAsRecentlyUsed(img_index)
                                              }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button size="small" style={{ marginTop: 10 }}>
                                            {
                                                this.getDaysSinceLastUsed(img["last_used_at"]) < 2
                                                ? <> <CheckCircleTwoTone twoToneColor="#52c41a"/> Recently Used</>
                                                : 'Mark Image As Used Now'
                                            }
                                            </Button>
                                        </Popconfirm>
                                        <Row>
                                        <Popconfirm
                                            title="Do you really want to mark this image as SAFE for Oppo / Vivo?"
                                            onConfirm={() => {
                                                this.markImageAsSafe(img_index)
                                              }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button size="small" style={{ marginTop: 10 }}>
                                            {
                                                img.allowlist_partner_ids && this.state.partner_id_options_values.every((id) => img.allowlist_partner_ids.includes(id))
                                                ? <> <CheckCircleTwoTone twoToneColor="#52c41a"/> SAFE for Oppo / Vivo</>
                                                : 'Mark It SAFE for Oppo / Vivo'
                                                }
                                            </Button>
                                        </Popconfirm>

                                        </Row>
                                        <a
                                            href={this.state.aws_cdn_prefix + img["id"] + ".jpg"}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Button style={{ marginTop: 10 }}>
                                                Download Image
                                            </Button>
                                        </a>
                                    </Card>
                            </Col>
                            )
                        })}
                        </Row>
                        </> 
                    )
                }
                </Row>
            </Content>
          </Layout>
            </div>
        )

        return (
            <>
                <AuthenticatedTemplate>
            <Layout style={{'min-height':'100vh'}}>
            <NavBar
                authHandler={async (instance) => {await this.authHandler(instance)}}
                authRefresh={this.authRefresh}
                signin_name={this.props.username}
            />
            <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
              <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                {createForm}
              </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
            <img
                src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                alt=""
                style={{
                    height:'20px',
                    display:'inline',
                    paddingRight:'10px'
                }}/>
                 ©2021 Created by TAG AI Team</Footer>
          </Layout>

        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <NoAuth />
        </UnauthenticatedTemplate>
        </>
        )
    }
}
